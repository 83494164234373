import {useEffect, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {designersCatalogSelector, getOrgStructures} from "../../../selectors/CatalogSelectors";
import {getCurrentProject} from "../../../selectors/generalSelectors";
import {createKp, deleteKp, getProjectKpList, kpCreated, updateKp} from "../../../slices/KPSlice";
import {getUsers} from "../../../slices/PersonSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ControlFieldsChanges from "../../../utilities/ControllChanges";
import {DateInput, NoteInput, TextInput} from "../../Inputs";
import {Select as SelectField} from "../../Inputs/SelectField";

// import remindImg from "./src/Data_Time_yellow.png";
import "./src/AddKPForm.scss";

const ProjectAddKPForm = ({updates, onMakeEffect, setIsEdited}) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const {currentKp} = useSelector(state => state.kp);
  const currentProject = useSelector(getCurrentProject);

  const projectKpList = useSelector(getProjectKpList);

  const kpCatalog = useMemo(() => {
    return projectKpList.map(co => {
      return {value: co.coId, title: co.coNumber};
    });
  }, [projectKpList]);
  const designersCatalog = useSelector(designersCatalogSelector);

  const formikRef = useRef();

  const isDisabled = !currentProject;

  const onKpDelete = () => {
    if (currentKp) {
      Swal.fire({
        title: t("Sure"),
        text: t("Irrevocably"),
        icon: "question",
        confirmButtonText: t("Yes"),
        showCancelButton: true,
        cancelButtonText: t("No"),
      }).then(value => {
        if (value.isConfirmed) {
          dispatch(deleteKp(currentKp.coId));
        }
      });
    } else {
      Swal("Спочатку оберіть кп");
    }
  };

  // функция срабатывает когда выбор kp происходит из инпута в форме. Принимает айди проекта и устанавливает айди текущего проекта
  // const makeEffect = id => {
  //   if (id) {
  //     onMakeEffect(id);
  //   }
  // };

  ////  for table
  const today = useMemo(() => {
    var t = new Date();
    t.setHours(0);
    t.setMinutes(0);
    t.setSeconds(0);
    t.setMilliseconds(0);
    return t;
  }, []);

  const onNoteRed = note => {
    if (currentKp) {
      dispatch(updateKp({newKp: {note: note}, coId: currentKp.coId})).then(() => {
        setIsEdited(false);
      });
    }
  };

  const onUpdate = values => {
    if (new Date(values.startDate) > new Date(values.finalDate)) {
      // console.log("no no ");
      formikRef.current.setErrors({finalDate: "Later than"});
      return;
    }
    const kp = {
      managerCoId: values.managerCoId,
      orgStructureId: values.orgStructureId,
      designerId: !values.designerId || values.designerId === "" ? null : values.designerId,
      designBonus: values.designBonus,
      startDate: values.startDate,
      finalDate: values.finalDate,
      note: values.note,
      designerName: values.designer?.name,
      // managerCoName: values.managerCo?.name,
    };
    // console.log(new Date(values.startDate));

    dispatch(updateKp({newKp: kp, coId: currentKp.coId}));
  };
  const validatingObj = Yup.object().shape({
    startDate: currentKp
      ? Yup.date().required([t("Enter date")])
      : Yup.date()
          .min(today, `${[t("Not earlier")]} ${today.toLocaleDateString()}`)
          .required([t("Enter date")]),
    finalDate: Yup.date()
      .required([t("Enter date")])
      .min(Yup.ref("startDate"), ({min}) => `${[t("Later than")]} ${min.toLocaleDateString()}!`),
    managerCoId: Yup.number().required([t("Existing M")]),
  });

  const currentFormState = useMemo(() => {
    if (!currentKp) {
      return {
        coId: "",
        // managerCoName: currentProject?.userName || "",
        managerCoId: currentProject?.userId || currentProject?.managerId || "",
        note: "",
        startDate: "",
        finalDate: "",
        remind: "",
        designerName: currentProject ? currentProject.designerName : "",
        designerId: currentProject ? currentProject.designerId : null,
        designBonus: currentKp?.designBonus || currentProject?.designer?.bonus,
        status: "",
        orgName: currentProject?.orgStructure[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"] || "",
        orgStructureId: currentProject ? currentProject.orgStructure.orgStructureId : "",
      };
    }

    return {
      ...currentKp,
      orgName: currentKp.orgStructure[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
      designBonus: currentKp?.designBonus || currentProject?.designer?.bonus,
      designerName: currentKp?.designer?.name,
      // managerCoName: currentKp?.managerCo?.name,
    };
  }, [currentKp, currentProject]);

  const onCreateKp = values => {
    dispatch(
      createKp({
        projectId: currentProject.projectId,
        newCo: {
          managerCoId: values.managerCoId,
          designerId: values.designerId,
          orgStructureId: values.orgStructureId,
          designBonus: values.designBonus,
          startDate: values.startDate,
          finalDate: values.finalDate,
          note: values.note,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        // getSuccess(`${t("CO")} ${res.payload.co.coNumber} ${t("created")}`);
        Swal.fire({
          title: "",
          text: `${t("Co added", {coNumber: res.payload.co.coNumber})}`,
          icon: "info",
          confirmButtonText: "OK",
        });
        const {
          payload: {co},
        } = res;

        dispatch(
          kpCreated({
            ...co,
            managerCoId: co.managerCoId,
            managerCoName: co.managerCo.name,
            designerName: co.designer?.name || "",
            designerId: co.designerId || null,
            designBonus: co.designBonus || null,
          }),
        );
      }
    });
  };

  const onSubmitForm = values => {
    if (currentKp) {
      onUpdate(values);
      return;
    } else {
      onCreateKp(values);
    }
  };

  const onForcedUpd = () => {
    if (currentKp && formikRef.current) {
      formikRef.current.submitForm(); // Вызываем метод submitForm() через реф
    }
  };

  return (
    <Formik
      initialValues={currentFormState}
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validatingObj}
      onSubmit={onSubmitForm}
      innerRef={formikRef}
    >
      <Form className="add-kp-form">
        <ControlFieldsChanges editFunc={setIsEdited} forcedUpdate={updates} updFunc={onForcedUpd} />
        <Strange />
        <div className="add-kp-form__container">
          <SelectField
            label={t("CO №")}
            name="coId"
            options={kpCatalog}
            width="69%"
            makeEffect={id => {
              if (id?.value !== "") onMakeEffect(id?.value);
            }}
            readOnly={isDisabled}
            selectOnly={!!currentKp}
          />
          <div style={{position: "relative"}}>
            <ManagerField currentProject={currentProject} />
          </div>
          <SelectField
            label={t("Designer")}
            name="designerName"
            width="69%"
            options={[]}
            multi={true}
            readOnly={isDisabled}
          />

          <TextInput
            label={t("Designer %")}
            name="designBonus"
            type="number"
            width="66%"
            className="procent"
            min="0"
            max="100"
            step="any"
            question={t("Des fee")}
            readOnly={isDisabled}
          />
        </div>

        <div className="add-kp-form__container">
          <div
            className=" flex_container "
            style={{width: "100%", justifyContent: "space-between", marginBottom: "10px"}}
          >
            <DateInput
              label={t("From")}
              name="startDate"
              flatpikrConfig={{
                minDate: moment().format("DD-MM-YYYY"),
              }}
              readOnly={!!currentKp || isDisabled}
            />
            <DateInput
              label={t("To")}
              name="finalDate"
              flatpikrConfig={{
                minDate: moment().format("DD-MM-YYYY"),
              }}
              readOnly={isDisabled}
            />

            {/* <div className="" style={{display: "flex", flexWrap: "nowrap"}}>
              <img
                src={remindImg}
                alt="remind"
                style={{height: "16px", width: "16px", marginTop: "1px"}}
                draggable="false"
              />
              <Field label="" id="remind" name="remind" type="checkbox" />
            </div> */}
          </div>
          <TextInput
            label={t("Offic")}
            name="orgName"
            autolabel
            labelStyles={{width: "3em"}}
            width="88.5%"
            readOnly={true}
          />
          <div className="fixed_button__container" style={{marginTop: "5%"}}>
            <FunctionButton
              type="submit"
              name={t("Create CO")}
              disabled={currentKp || !currentProject ? true : false}
            />

            <FunctionButton
              name={t("Delete CO")}
              className="fixed_button fixed_button__delete "
              // disabled={!currentKp}
              disabled={true}
              onClick={onKpDelete}
            />
          </div>
        </div>
        <div className="" style={{width: "100%"}}>
          <NoteInput
            label={t("About")}
            action={onNoteRed}
            labelStyles={{width: "8.3em"}}
            name="note"
            type="text"
            width="100%"
            readOnly={isDisabled}
          />
        </div>
      </Form>
    </Formik>
  );
};

const Strange = () => {
  const {values, validateForm} = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [values.managerKpId]);
};

const ManagerField = ({currentProject}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const users = useSelector(getUsers);
  const managersCoOptions = users.map(user => ({value: user.userId, title: user.name}));
  const isDisabled = useMemo(() => !currentProject, [currentProject]);

  const {
    values: {managerCoId},
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (managerCoId && managerCoId != "") {
      var orgStruc = users.find(user => user.userId === managerCoId)?.orgStructure;
      setFieldValue("orgName", orgStruc?.[language === "en" ? "fullNameEng" : "fullNameUkr"]);
    }
  }, [managerCoId]);

  return (
    <SelectField
      label={t("CO Manager")}
      name="managerCoId"
      width="69%"
      options={managersCoOptions}
      readOnly={isDisabled}
    />
  );
};

ProjectAddKPForm.propTypes = {updates: PropTypes.bool, onMakeEffect: PropTypes.func, setIsEdited: PropTypes.func};

export default ProjectAddKPForm;
