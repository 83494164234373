import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../../conteiners/ReactTable";
import {MoneyInput, NumberInput} from "../../../../../Inputs";
import ModalForm from "../../../../../Modal/FormModal";
import CurencySelect from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/CurencySelect";
import DtCtPart from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/DtCtPart";
import PdfPreviewModal from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/pdfPreviewModal";
import useOrderDirectiveView from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/useOrderDirectiveView";
import OrderTypeComposedPart, {
  ORDER_COMPOSED_PART_TYPES,
} from "../../../../../Project/ProjectDirectiveList/OrderTypeComposedPart/OrderTypeComposedPart";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import Spinner from "../../../../../spinner/Spinner";
import {clearStatementDirective, deleteOrder, setFinOrder} from "../../../../FinSettings/FinSettingsSlice";
import {CONDUCTED_ORDER_TRANSACTIONS_TABLE_COLUMNS, ORDER_TRANSACTIONS_TABLE_COLUMNS} from "../constants";

import DirectiveCreation from "./DirectiveCreation";

const DirectiveAndOrder = ({
  orderId,
  readOnly,
  onCreateDirectiveBtnClick,
  noCounterpartyAssociation,
  hasAssociation,
  isDirectiveCreated,
}) => {
  const {
    upperTableData,
    upperTableColumns,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    // onExitBtnClick,
    currentTransactionId,
    onCreateOrder,
    currentOrderId,
    onRunOrder,
    runOrderBtnDisabled,
    // onDeleteOrder,
    isOrderSaved,
    onCompleateOrder,
    isOrderCompleate,
    onTransactionSelect,
    printModalActive,
    setPrintModalActive,
    pdfFile,
    currentOrderNumber,
    checkExchangeRate,
    currentOrder,
    loading,
  } = useOrderDirectiveView(orderId);
  const {t} = useTranslation();
  const blockOkBtn = isOrderCompleate || isOrderSaved || upperTableData?.length > 0;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFinOrder(null));
    checkExchangeRate(980, true);
    return () => {
      dispatch(setFinOrder(null));
    };
  }, []);

  const onDeleteOrder = () => {
    Swal.fire({
      title: t("Sure"),
      text: t("Order will be removed", {orderNumber: currentOrder.orderNumber}),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteOrder(currentOrderId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            dispatch(clearStatementDirective());
          }
        });
      }
    });
  };

  return (
    <div className="pr w100">
      {loading && <Spinner />}
      <div className="fc nowrap g2">
        <DirectiveCreation
          onCreateDirectiveBtnClick={onCreateDirectiveBtnClick}
          noCounterpartyAssociation={noCounterpartyAssociation}
          hasAssociation={hasAssociation}
          isDirectiveCreated={isDirectiveCreated}
        />
        <div className="row asfe aic jcc g1" style={{width: "60%"}}>
          <CurencySelect
            name="paymentCurrency"
            title="Payment currency"
            required
            makeEffect={e => checkExchangeRate(e?.value, true)}
            readOnly={blockOkBtn}
          />
          <MoneyInput
            name="paymentSumm"
            width="7em"
            label={t("Payment Sum")}
            required
            disabled={false}
            currency={null}
            placeholder={""}
            readOnly={blockOkBtn}
          />
          <NumberInput
            name="exchangeRate"
            width="6em"
            label={t("exchange rate") + " VAL / EUR:"}
            autolabel
            readOnly={true}
          />
          {!blockOkBtn && (
            <FunctionButton name="Ok" onClick={onRunOrder} disabled={!runOrderBtnDisabled} width={"3em"} />
          )}
        </div>
      </div>
      <div className="row nowrap gap pt5">
        <OrderTypeComposedPart readOnly className="fGrow" type={ORDER_COMPOSED_PART_TYPES.VIEW_FUll}>
          <div className="row w100 jcsb">
            <FunctionButton
              onClick={onCompleateOrder}
              name="Conduct"
              autoWidth
              disabled={!isOrderSaved}
              tooltipMessage={isOrderSaved ? null : "Order must be saved"}
            />

            {/* <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth /> */}
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol" style={{width: "77%"}}>
          <DtCtPart isOrderCompleate={isOrderCompleate} />
          <div className="fcCol mw100">
            <div className="fGrow w100 mw100 h100">
              <ReactTable
                columns={upperTableColumns}
                defaultData={upperTableData}
                // selectable={false}
                current={currentTransactionId}
                onSelect={onTransactionSelect}
                className="mb scrollX"
                style={{height: "7rem"}}
              />
            </div>
            {!readOnly && (
              <div className={"fc nowrap jcfe w100 mb"}>
                <FunctionButton
                  name="Create order"
                  autoWidth
                  onClick={onCreateOrder}
                  disabled={!currentOrderId || upperTableData?.length === 0}
                />
              </div>
            )}
            <div className="fGrow w100 mw100 h100">
              <ReactTable
                columns={lowerTableColumns}
                defaultData={lowerTableData}
                // selectable={false}
                current={currentTransactionId}
                onSelect={onTransactionSelect}
                className=" mb scrollX"
                style={{height: "9rem"}}
              />
            </div>
            <div className="row jcfe w100">
              {!readOnly && (
                <FunctionButton
                  onClick={onDeleteOrder}
                  disabled={!currentOrderId}
                  name="Delete order"
                  autoWidth
                  style={{marginRight: "40px"}}
                />
              )}
              <FunctionButton
                onClick={onPrintOrderBtnClick}
                name="Print an order"
                autoWidth
                disabled={!isOrderCompleate}
                tooltipMessage={isOrderCompleate ? null : "Order must be saved and completed"}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalForm
        closable={true}
        Component={PdfPreviewModal}
        label={t("Printing")}
        active={printModalActive}
        setModalActive={setPrintModalActive}
        pdfFile={pdfFile}
        fileName={currentOrderNumber}
      />
    </div>
  );
};

export default DirectiveAndOrder;
