import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import IndeterminateCheckbox from "../../../../conteiners/reactTable/IndeterminateCheckbox";
import ControlCheck, {CONTROL_CHECK_TYPES} from "../../../../Inputs/controlCheck/ControlCheck";
import {getCurrentSku} from "../../../SkuSlice";
import useVWCharInputsService from "../../../viewWidthCharInputs/vwCharInputsService";

import SkuVwCharCheckboxe from "./SkuVwCharCheckboxe";

export const VW_CHARACTERISTICS_TYPES = Object.freeze({SKU_CREATE_FORM: "SkuCreateForm"});

const SkuModalVwCharacteristics = ({numberOfChecked, setNumberOfChecked, type}) => {
  const {t} = useTranslation();

  const currentSku = useSelector(getCurrentSku);

  const {
    values: {length: l, width: w, height: h, volumeCheck, areaCheck},
    setFieldValue,
  } = useFormikContext();

  const {area, dimension, volume, quantity, skuMeasurementUnit, pallet, mass} = useVWCharInputsService(
    "6.5em",
    "Dimension",
  );

  useEffect(() => {
    if (
      areaCheck &&
      l > 0 &&
      w > 0 &&
      (!currentSku.area || currentSku.area === "" || currentSku.area == "0" || currentSku.area == 0)
    ) {
      var are = l * w;
      setFieldValue("area", are);
    }
    if (
      volumeCheck &&
      l > 0 &&
      w > 0 &&
      h > 0 &&
      (!currentSku.volume || currentSku.volume === "" || currentSku.volume == "0" || currentSku.volume == 0)
    ) {
      var vol = l * w * h;
      setFieldValue("volume", vol);
    }
  }, [l, w, h, volumeCheck, areaCheck]);

  useEffect(() => {
    if (currentSku && currentSku instanceof Object) {
      const {weightCheck, areaCheck, volumeCheck, overallSizeCheck, palletCheck} = currentSku;
      const mas = [weightCheck, areaCheck, volumeCheck, overallSizeCheck, palletCheck];
      const count = mas.filter(element => element === true).length;
      setNumberOfChecked(count);
    } else {
      setNumberOfChecked(0);
    }
  }, [currentSku]);

  return (
    <div className="vwCharacter h100 pb0_5 fcCol aist gap jcsb">
      <div className="header">{t("VWC header")}</div>

      <div className="fcCol aist">
        <div className="fc jcsb autolabel gap" style={{marginBottom: "1em"}}>
          {skuMeasurementUnit}
          {quantity}
        </div>
        <SkuVwCharCheckboxe
          name="overallSizeCheck"
          setNumberOfChecked={setNumberOfChecked}
          numberOfChecked={numberOfChecked}
        >
          {dimension}
        </SkuVwCharCheckboxe>
        <SkuVwCharCheckboxe name="areaCheck" setNumberOfChecked={setNumberOfChecked} numberOfChecked={numberOfChecked}>
          {area}
        </SkuVwCharCheckboxe>
        <SkuVwCharCheckboxe
          name="volumeCheck"
          setNumberOfChecked={setNumberOfChecked}
          numberOfChecked={numberOfChecked}
        >
          {volume}
        </SkuVwCharCheckboxe>
        <SkuVwCharCheckboxe
          name="weightCheck"
          setNumberOfChecked={setNumberOfChecked}
          numberOfChecked={numberOfChecked}
        >
          {mass}
        </SkuVwCharCheckboxe>
        <SkuVwCharCheckboxe
          name="palletCheck"
          setNumberOfChecked={setNumberOfChecked}
          numberOfChecked={numberOfChecked}
        >
          {pallet}
        </SkuVwCharCheckboxe>
      </div>

      <div>
        {type === VW_CHARACTERISTICS_TYPES.SKU_CREATE_FORM && (
          <ControlCheck
            name="vwChangedBy"
            date="vwChangeDate"
            initialChecked={false}
            type={CONTROL_CHECK_TYPES.CHANGED_BY}
          />
        )}
        <div className="row aic gap">
          {type === VW_CHARACTERISTICS_TYPES.SKU_CREATE_FORM && (
            <IndeterminateCheckbox checked={volumeCheck && areaCheck} readOnly />
          )}
          <ControlCheck name="dd" date="ddd" initialChecked={false} />
          {type === VW_CHARACTERISTICS_TYPES.SKU_CREATE_FORM && <FunctionButton disabled name="Ok" autoWidth />}
        </div>
      </div>
    </div>
  );
};

export default SkuModalVwCharacteristics;
