import {Children, cloneElement, isValidElement, useEffect, useRef} from "react";
import {Field, useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import "./utils/fieldWithSample.scss";

const FieldWithSample = ({label, children, labelWrap, name, readOnly = false, ...props}) => {
  const {values, handleChange} = useFormikContext();

  const isChecked = Boolean(values?.[name]);

  const inputrefs = [];

  const focusField = e => {
    handleChange(e);
    if (e.target.value && inputrefs?.length > 0) {
      inputrefs[0].focus();
    }
  };

  const setref = ref => {
    inputrefs.push(ref);
  };

  return (
    <div className={`fieldWithSample ${labelWrap ? "" : "autolabel"}`} style={props.style}>
      <label className="label " style={{display: "flex", whiteSpace: "nowrap"}}>
        <Field
          name={name}
          {...props}
          type="checkbox"
          style={{marginBottom: "4px"}}
          disabled={readOnly}
          onChange={focusField}
        />
        {label && <span>{label}</span>}
      </label>
      <div
        className={isChecked ? "" : "fieldWithSample__disabled"}
        style={{width: "100%", display: "flex", flexWrap: "nowrap"}}
        tabIndex="0"
      >
        {Children.map(children, (child, index) => {
          if (isValidElement(child) && index === 0) {
            return cloneElement(child, {
              setref: setref,
              readOnly: !isChecked || readOnly,
            });
          }
          return child;
        })}
      </div>
    </div>
  );
};

FieldWithSample.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  props: PropTypes.array,
};

export default FieldWithSample;
