import React from "react";
import {useField} from "formik";
import PropTypes from "prop-types";

import crosIcon from "../../images/icons/closedelete.png";
import saveImg from "../../images/icons/SaveDisket.png";
import ImageButton from "../../utilities/Buttons/ImageBtn";

import "./utils/Inputs.scss";

const NoteInput = ({
  action,
  label,
  width = "100%",
  autolabel,
  labelStyles,
  generalClassName = "",
  deleteIcon = false,
  onDelete = () => {},
  actionDisabled = false,
  deleteDisabled = false,
  labelWrap,
  style,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div
      style={{marginTop: "5px", marginBottom: "10px", display: "flex", color: "#426BA6", ...style}}
      className={`project_input autolabel${generalClassName} ${labelWrap ? "" : "autolabel"}`}
    >
      {label && (
        <label
          htmlFor={props.name}
          style={{whiteSpace: "pre", ...labelStyles}}
          className={`${autolabel ? "autolabel" : ""}`}
        >
          {/* {  typeof label === "string" ? t(label) : label  } */}
          {label}
        </label>
      )}
      <div className="project_input " style={{width: width, padding: 0}}>
        <input
          {...props}
          {...field}
          style={{width: "100%", ...(props.readOnly ? {cursor: "default"} : {background: "white"})}}
          autoComplete="off"
          value={field.value || ""}
        />
      </div>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      {/* <img
        src={saveImg}
        alt="save"
        style={{height: "1.4em", width: "auto", cursor: "pointer"}}
        onClick={() => action(field.value)}
        title="зберегти примітку"
        draggable="false"
      /> */}
      <ImageButton
        src={saveImg}
        width={1.4}
        height={1.4}
        onClick={() => action(field.value)}
        disabled={field.value === "" || !field.value || actionDisabled}
      />
      {deleteIcon && <ImageButton src={crosIcon} size={1.5} onClick={onDelete} disabled={deleteDisabled} />}
    </div>
  );
};
NoteInput.propTypes = {
  action: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  generalClassName: PropTypes.string,
  props: PropTypes.array,
};

export default NoteInput;
