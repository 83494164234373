import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

const PageContainer = ({title, children, loading, className = "", id}) => {
  const {t} = useTranslation();

  return (
    <div className="limitedSize" {...(id ? {id} : {})}>
      <p className="limitedSize_label">{typeof title === "string" ? t(title) : title}</p>
      <div className="overflow_container">
        <div className={`limitedSize__container ${className}`}>{loading ? <Spinner /> : children}</div>
      </div>
    </div>
  );
};
PageContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default PageContainer;
