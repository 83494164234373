import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {dateFormat, timeFormat} from "../../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../../utilities/formatAccountNumber";
import {toaster} from "../../../../../utilities/hooks/toaster";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import {alignCounterpartyAcc, fetchAllBankTransactions, selectBankTransaction} from "../../../FinBlockSlice";
import {
  addNewStatementDirective,
  fetchAllDirectives,
  fetchFinOrder,
  selectStatementDirective,
} from "../../../FinSettings/FinSettingsSlice";
import {formTransactionStatus} from "../../BankClient";

const useAccAssotiation = () => {
  const dispatch = useDispatch();

  const bankTransaction = useSelector(selectBankTransaction);
  const statementDirective = useSelector(selectStatementDirective);

  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [isModalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (!statementDirective?.orders) {
      setCurrentOrderId(null);
      return;
    }
    const reversed = [...statementDirective.orders]?.reverse();
    const order = reversed.find(order => order?.typeId === 3);
    setCurrentOrderId(order?.orderId);
    if (order?.orderId) dispatch(fetchFinOrder(order?.orderId));
  }, [statementDirective]);

  const realTransactionTableData = useMemo(() => {
    const statement = bankTransaction?.bankClientStatement;
    if (!statement) return [];

    const counterparty = {
      name: statement?.counterparty,
      iban: statement?.counterpartyIban,
      edrpou: statement?.counterpartyEdrpou,
      bankMfis: statement?.counterpartyBankMfis,
      bank: statement?.counterpartyBank,
    };
    const initial = {
      name: "ГРУПА КОМПАНIЙ ТЕХНО ПП",
      iban: statement?.iban,
      edrpou: statement?.edrpou,
      bankMfis: statement?.bankMfis,
    };
    const isDebit = Boolean(statement?.isDebit);
    const payer = isDebit ? initial : counterparty;
    const recipient = isDebit ? counterparty : initial;
    return [
      {
        id: statement?.statementId,
        number: 1,
        bankP: payer?.bank,
        mfiP: payer?.bankMfis,
        docNumber: statement?.documentNumber,
        docDate: dateFormat(statement?.createdAt),
        date: dateFormat(statement?.dateAndTime),
        time: timeFormat(statement?.dateAndTime),
        costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
        revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
        currency: bankTransaction.currency.shortName,
        payerAccountNumber: formatAccountNumber(payer?.iban),
        requisitesP: payer?.edrpou,
        payerName: payer?.name,
        paymentPurpose: statement?.paymentPurpose,
        recipientAccountNumber: formatAccountNumber(recipient?.iban),
        requisitesR: recipient?.edrpou,
        recipientName: recipient?.name,
        recipientBank: recipient?.bank,
        mfiR: recipient?.bankMfis,
        rate: statement?.rate,
        equivalent: statement?.nbuEquivalentSum,
        commissionsAmmount: statement?.feesSum,
        remainder: isDebit ? statement?.debitAccount?.balance : statement?.creditAccount?.balance, // !!!!!
        reference: "?",
        operationCode: statement?.operationCode,
        status: "?",
        trId: statement?.transactionUuid,
        bank: statement?.bank,
        mfi: statement?.bankMfis,
        accountNumber: formatAccountNumber(statement?.iban),
        accHolderName: payer.name,
        counterpartyAccountNumber: formatAccountNumber(statement?.counterpartyIban),
        counterpartyName: statement?.counterparty,
        counterpartyBank: statement?.counterpartyBank,
        mfiC: statement?.counterpartyBankMfis,
        requisitesC: statement?.counterpartyEdrpou,
      },
    ];
  }, [bankTransaction?.transactionId]);

  const onRealTransactionSelect = () => {};

  const associatedTransactionTableData = useMemo(() => {
    if (!bankTransaction) return [];

    const statement = bankTransaction?.bankClientStatement;

    const counterparty = {
      name: statement?.counterparty,
      iban: statement?.counterpartyIban,
      edrpou: statement?.counterpartyEdrpou,
      bankMfis: statement?.counterpartyBankMfis,
      bank: statement?.counterpartyBank,
    };
    const initial = {
      name: "ГРУПА КОМПАНIЙ ТЕХНО ПП",
      iban: statement?.iban,
      edrpou: statement?.edrpou,
      bankMfis: statement?.bankMfis,
    };
    const isDebit = Boolean(statement?.isDebit);
    const payer = isDebit ? initial : counterparty;
    const recipient = isDebit ? counterparty : initial;

    const {creditAccount, debitAccount} = bankTransaction;

    return bankTransaction?.internalTransaction
      ? [
          {
            id: bankTransaction?.transactionId,
            number: 1,
            trId: bankTransaction?.bankClientStatement?.transactionUuid,
            accountNumber: formatAccountNumber(isDebit ? debitAccount.accountNumber : creditAccount.accountNumber),
            counterparty: statement?.counterparty,
            date: dateFormat(bankTransaction?.createdAt),
            status: formTransactionStatus(bankTransaction),
            operationType: t("To the Payer`s balance"),
            costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
            revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
            counterpartyAccountNumber: formatAccountNumber(
              isDebit ? creditAccount.accountNumber : debitAccount.accountNumber,
            ),
            currency: bankTransaction?.currency?.shortName,
            payerAccountNumber: formatAccountNumber(bankTransaction?.debitAccount?.accountNumber),
            requisitesP: payer?.edrpou,
            payerName: payer?.name,
            paymentPurpose: bankTransaction?.paymentPurpose,
            recipientAccountNumber: formatAccountNumber(bankTransaction?.creditAccount?.accountNumber),

            requisitesR: recipient?.edrpou,
            recipientName: recipient?.name,
            rate: statement?.rate,
            equivalent: bankTransaction?.bankClientStatement?.nbuEquivalentSum,
            remainder: numberFormat(bankTransaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
            counterpartyName: bankTransaction?.bankClientStatement?.counterparty,
            requisitesC: bankTransaction?.bankClientStatement?.counterpartyEdrpou,
          },
          {
            id: bankTransaction?.internalTransaction?.transactionId,
            number: 2,
            trId: bankTransaction?.bankClientStatement?.transactionUuid,
            accountNumber: formatAccountNumber(
              isDebit
                ? bankTransaction?.internalTransaction?.debitAccount.accountNumber
                : bankTransaction?.internalTransaction?.creditAccount.accountNumber,
            ),

            counterparty: statement?.counterparty,
            date: dateFormat(bankTransaction?.internalTransaction?.createdAt),
            status: formTransactionStatus(bankTransaction),
            operationType: t("To the Payer`s balance"),
            costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
            revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
            currency: bankTransaction?.currency?.shortName,
            counterpartyAccountNumber: formatAccountNumber(
              isDebit
                ? bankTransaction?.internalTransaction?.creditAccount.accountNumber
                : bankTransaction?.internalTransaction?.debitAccount.accountNumber,
            ),
            payerAccountNumber: formatAccountNumber(bankTransaction?.internalTransaction?.debitAccount?.accountNumber),
            requisitesP: "",
            //  payer?.edrpou,
            payerName: bankTransaction?.debitAccount?.accountName,
            paymentPurpose: bankTransaction?.internalTransaction?.paymentPurpose,
            recipientAccountNumber: formatAccountNumber(
              bankTransaction?.internalTransaction?.creditAccount?.accountNumber,
            ),
            requisitesR: "",
            // recipient?.edrpou,
            recipientName: bankTransaction?.creditAccount?.accountName,
            rate: statement?.rate,
            equivalent: bankTransaction?.bankClientStatement?.nbuEquivalentSum,
            remainder: numberFormat(bankTransaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
            counterpartyName: bankTransaction?.bankClientStatement?.counterparty,
            requisitesC: bankTransaction?.bankClientStatement?.counterpartyEdrpou,
          },
        ]
      : [
          {
            id: bankTransaction?.transactionId,
            number: 1,
            trId: bankTransaction?.bankClientStatement?.transactionUuid,
            accountNumber: formatAccountNumber(isDebit ? debitAccount.accountNumber : creditAccount.accountNumber),
            counterparty: statement?.counterparty,
            date: dateFormat(bankTransaction?.createdAt),
            status: formTransactionStatus(bankTransaction),
            operationType: t("To the Payer`s balance"),
            costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
            revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
            currency: bankTransaction?.currency?.shortName,
            payerAccountNumber: formatAccountNumber(bankTransaction?.debitAccount?.accountNumber),
            counterpartyAccountNumber: formatAccountNumber(
              isDebit ? creditAccount.accountNumber : debitAccount.accountNumber,
            ),
            requisitesP: payer?.edrpou,
            payerName: payer?.name,
            paymentPurpose: bankTransaction?.paymentPurpose,
            recipientAccountNumber: formatAccountNumber(bankTransaction?.creditAccount?.accountNumber),
            requisitesR: recipient?.edrpou,
            recipientName: recipient?.name,
            rate: statement?.rate,
            equivalent: bankTransaction?.bankClientStatement?.nbuEquivalentSum,
            remainder: numberFormat(bankTransaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
            counterpartyName: bankTransaction?.bankClientStatement?.counterparty,
            requisitesC: bankTransaction?.bankClientStatement?.counterpartyEdrpou,
          },
        ];
  }, [bankTransaction?.transactionId, bankTransaction?.internalTransaction]);
  const onAssociatiedTransactionSelect = () => {};

  const onSaveAssociationBtnClick = values => {
    if (!bankTransaction || !values.clientId) return;

    dispatch(alignCounterpartyAcc({id: bankTransaction?.transactionId, clientId: values.clientId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Association saved");
        dispatch(fetchAllBankTransactions());
      }
    });
  };

  const onCreateDirectiveBtnClick = () => {
    if (!bankTransaction) return;

    dispatch(fetchAllDirectives({clientId: bankTransaction?.counterpartyAccount?.owner?.clientId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        if (resp.payload.length > 0) {
          setModalActive(true);
        } else {
          dispatch(
            addNewStatementDirective({
              clientId: bankTransaction?.counterpartyAccount?.owner?.clientId,
              transactionId: bankTransaction?.transactionId,
            }),
          );
        }
      }
    });
  };
  return {
    isModalActive,
    currentOrderId,
    onCreateDirectiveBtnClick,
    onSaveAssociationBtnClick,
    onAssociatiedTransactionSelect,
    associatedTransactionTableData,
    realTransactionTableData,
    onRealTransactionSelect,
    bankTransaction,
    statementDirective,
    setModalActive,
  };
};

export default useAccAssotiation;
