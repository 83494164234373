import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import ReactTable from "../../../../../conteiners/ReactTable";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchExternalAccounts, selectExternalAccounts} from "../../../FinSettingsSlice";
import {ACC_ASSOTIATION_TABLE_COLUMNS} from "../constants";

import formatAccountNumber from "./../../../../../../utilities/formatAccountNumber";

const AccAssociationTable = () => {
  const loading = false;
  const dispatch = useDispatch();
  const externalAccounts = useSelector(selectExternalAccounts);
  const {
    values: {externalAccId},
    setFieldValue,
  } = useFormikContext();

  const columns = useColumns(ACC_ASSOTIATION_TABLE_COLUMNS);
  const defaultData = useMemo(
    () =>
      externalAccounts?.map((acc, i) => ({
        id: acc.accountId,
        number: ++i,
        date: "?",
        bank: "?",
        mfi: acc?.bankMfis,
        externalAccNumber: formatAccountNumber(acc?.iban),
        externalAccNaming: "?",
        internalAccNumber: formatAccountNumber(acc?.internalAccount?.accountNumber),
        accountType: acc?.type?.name || "-",
        internalAccNaming: acc?.internalAccount?.accountName,
        payerAcc: formatAccountNumber(acc?.payerAccount?.accountNumber),
        counterpartyType: "?",
        clientNumber: "?",
      })) || [],
    [externalAccounts],
  );

  useEffect(() => {
    dispatch(fetchExternalAccounts());
  }, []);

  return (
    <div className="fGrow fcCol">
      <ReactTable
        className="fGrow scrollX"
        // tableClassName="h100"
        columns={columns}
        defaultData={defaultData}
        current={externalAccId}
        onSelect={id => {
          setFieldValue("externalAccId", id);
        }}
        loading={loading}
      />
    </div>
  );
};

export default AccAssociationTable;
