import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {fetchClients, selectAllClients} from "../../../../../../slices/PersonSlice";
import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../../../utilities/ToolTip";
import {Select} from "../../../../../Inputs/SelectField";

const CounterpartyAssociation = ({onSaveAssociationBtnClick = () => {}, hasAssociation}) => {
  const dispatch = useDispatch();

  const {values} = useFormikContext();

  const clients = useSelector(selectAllClients);

  useEffect(() => {
    setTimeout(() => {
      if (!clients) {
        dispatch(fetchClients());
      }
    }, 500);
  }, [clients?.length]);

  const counterpartyTypeOptions = [{title: t("Client"), value: "client"}];
  const clientNumberOptions = clients?.map(({clientId, clientNumber}) => ({title: clientNumber, value: clientId}));
  const transactionTypeOptions = [{title: t("To the Payer`s balance"), value: "clientBalance"}];

  return (
    <ToolTip title={hasAssociation ? "Already has association" : ""} className="w100">
      <div className="border padding0_5 row aic w100">
        <span className="header">{t("Counterparty Association")}</span>
        <Select
          name="counterpartyType"
          label={t("Counterparty Type")}
          options={counterpartyTypeOptions}
          autolabel
          disabled={hasAssociation}
          optionsAbove
        />
        <Select
          name="clientId"
          label="№"
          options={clientNumberOptions}
          autolabel
          disabled={hasAssociation}
          optionsAbove
        />
        <Select
          name="transactionType"
          label={t("Transaction type ")}
          options={transactionTypeOptions}
          autolabel
          disabled={hasAssociation}
          optionsAbove
        />
        <FunctionButton
          name="Save association"
          onClick={onSaveAssociationBtnClick}
          autoWidth
          disabled={hasAssociation || !values.clientId || !values.counterpartyType || !values?.transactionType}
          // tooltipMessage={hasAssociation ? "Already has association" : ""}
        />
      </div>
    </ToolTip>
  );
};

export default CounterpartyAssociation;
