import {t} from "i18next";

import {TextAreaInput} from "../../../Inputs";
import ImgBox from "../../../Inputs/fileSelect/ImgBox/ImgBox";
import {Select} from "../../../Inputs/SelectField";
import useSkuCatalogsService from "../../SkuCatalogsService";
import {processImages} from "../constants/functions";

const FinishingAndColor = ({decorImages, colorImages, UploadDecorComp, UploadColorComp, readOnly = true}) => {
  const {colorsList, materialsList} = useSkuCatalogsService();

  return (
    <div className="w100 gap5">
      <div className="fc nowrap aist gap0_5" style={{flexGrow: "2"}}>
        <div className="fcCol" style={{width: "50%"}}>
          <Select label={t("Finishing")} name="materialId" options={materialsList} readOnly />
          <div className={`row ${UploadDecorComp ? "jcfe" : "jcc"} aife`}>
            <ImgBox showIndicators={false} classNames="smaller-image-box" imgs={processImages(decorImages) || []} />
            {UploadDecorComp && UploadDecorComp}
          </div>
        </div>
        <TextAreaInput label={t("Finishing type")} name="materialType" readOnly={readOnly} />
      </div>
      <div className="fc nowrap aist gap0_5" style={{flexGrow: "2"}}>
        <div className="fcCol" style={{width: "50%"}}>
          <Select label={t("Color")} name="colorId" options={colorsList} readOnly />
          <div className={`row ${UploadColorComp ? "jcfe" : "jcc"} aife`}>
            <ImgBox showIndicators={false} classNames="smaller-image-box" imgs={processImages(colorImages) || []} />
            {UploadColorComp && UploadColorComp}
          </div>
        </div>
        <TextAreaInput label={t("Color type")} name="colorType" readOnly={readOnly} />
      </div>
    </div>
  );
};

export default FinishingAndColor;
