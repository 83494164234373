import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {fetchData} from "../../../../../../hook/axios.hook";
import {TextAreaInput} from "../../../../../Inputs";
import ClientsSelect from "../../../../../Inputs/preparedInputs/ClientsSelect";
import {Select} from "../../../../../Inputs/SelectField";
import {fetchOwnerTypes, selectOwnerTypes} from "../../../FinSettingsSlice";

import AccountNumber from "./AccountNumber";

const PayerAccPart = ({disabled, children}) => {
  const ownerTypes = useSelector(selectOwnerTypes);
  const ownerTypeOptions = ownerTypes?.map(type => ({title: t(type.ownerType), value: type.ownerType})) ?? [];

  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const {clientNumber, payerCounterpartyTypeId} = values;

  useEffect(() => {
    dispatch(fetchOwnerTypes());
  }, []);

  useEffect(() => {
    if (!disabled) {
      if (clientNumber && payerCounterpartyTypeId) {
        const params = {balanceSheetAccId: 2699, ownerType: payerCounterpartyTypeId, ownerId: clientNumber};
        fetchData(`/finance/accounts`, "get", {params}).then(res => {
          setValues(prev => ({
            ...prev,
            payerAccountId: res?.accounts[0]?.accountId || null,
            payerAccount: res?.accounts[0]?.accountNumber || null,
            payerAccNaming: res?.accounts[0]?.accountName || "",
          }));
        });
      }
    }
  }, [clientNumber, payerCounterpartyTypeId]);

  return (
    <div className="fcCol w100 aistr">
      <div className="row">
        <span className="mb">{t("Payer account number")}</span>
        <Select
          selectOnly
          name="payerCounterpartyTypeId"
          label={t("Counterparty Type")}
          options={ownerTypeOptions}
          autolabel
          readOnly={disabled}
        />
      </div>

      <div className="row gap">
        <AccountNumber accountName={"payerAccount"} />
        <ClientsSelect readOnly={disabled} />
      </div>
      <TextAreaInput
        name="payerAccNaming"
        readOnly
        placeholder={"Payer account name"}
        height="2.5rem"
        className="mb0_5"
      />
      <div className="row w100 jcc">{children}</div>
    </div>
  );
};

export default PayerAccPart;
