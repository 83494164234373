import React from "react";

import "./copyCellStyle.scss";
const CopyCell = ({copyCell, handleClose, handleCopy}) => {
  return (
    <div
      className="expanded-cell"
      style={{
        position: "absolute",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        zIndex: "1000",
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-between",
        background: "#fff",
        border: "1px solid #a3b9d9",
        top: copyCell.top,
        left: copyCell.left,
        width: "fit-content",
        minWidth: copyCell.width,
        minHeight: copyCell.height,
        height: "fit-content",
        margin: "0",
        padding: "0.5rem",
        fontStyle: "italic",
        fontWeight: 400,
      }}
      onMouseLeave={handleClose}
    >
      <div className="expanded-cell-content">{copyCell.text}</div>
      <button type={"button"} className="expanded-cell-content-copy-btn" onClick={handleCopy}>
        Copy
      </button>
    </div>
  );
};

export default CopyCell;
