import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {fetchData} from "../../../hook/axios.hook";
import {getOrgStructures} from "../../../selectors/CatalogSelectors";
import {clientsFetched, getClients, getPersons, registrClient} from "../../../slices/PersonSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {DateInput, InvisibleInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

const AddClient = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData("/clients/getAll", "get").then(res => {
      dispatch(clientsFetched(res));
    });
    // dispatch(fetchClients());
  }, []);

  const persons = useSelector(getPersons);
  const clients = useSelector(getClients);
  const orgStructures = useSelector(getOrgStructures);

  const [isClient, setIsClient] = useState(false);

  const orgStructuresList = useMemo(() => {
    if (orgStructures && orgStructures.length > 0) {
      return orgStructures.map(item => {
        return {
          title: item[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
          value: item.orgStructureId,
        };
      });
    } else {
      return [];
    }
  }, [orgStructures]);

  const orgStructuresCodeList = useMemo(() => {
    if (orgStructures && orgStructures.length > 0) {
      return orgStructures.map(org => {
        return {
          title: org.shortName,
          value: org.orgStructureId,
        };
      });
    } else {
      return [];
    }
  }, [orgStructuresList]);

  const persList = persons.map(item => {
    return {
      title: item.fullName,
      value: item.personaId,
    };
  });

  const initialValues = useMemo(() => {
    return {
      fullName: "",
      personaId: "",
      orgFullName: "",
      orgStructureId: "",
      orgShortName: "",
      notes: "",
    };
  }, []);

  const findPersonFullName = id => {
    var persona_ = persons.find(per => per.personaId === +id);
    return persona_ ? persona_.personaId : "";
  };

  const onOfficeSelect = id => {
    var office = orgStructures.find(item => item.orgStructureId === +id);

    return office ? office.orgStructureId : "";
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required(),
          // orgStructureId:Yup.string().required('Такого офісу не існує')
        })}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          var persona = persons.find(persona => persona.personaId === values.fullName);

          if (persona) {
            // console.log("person find");
            dispatch(
              registrClient({
                personaId: +persona.personaId,
                orgStructureId: +values.orgShortName,
              }),
            ).then(() => {
              resetForm();
            });
          } else {
            Swal.fire({
              title: t("Doesnt exist"),
              text: t("Go to creation"),
              icon: "question",
              confirmButtonText: t("Yes"),
              showCancelButton: true,
              cancelButtonText: t("No"),
            }).then(value => {
              if (value.isConfirmed) {
                navigate(`/ba3a/admin/addPerson/${value.fullName.split(" ").join("&")}`);
              }
            });
          }
        }}
      >
        {({
          // values, errors, meta, isSubmitting, resetForm, handleChange,
          setFieldValue,
          setValues,
        }) => (
          <Form className="project_form">
            <TextInput
              label={t("Personal id")}
              name="personaId"
              func={e => {
                setFieldValue("fullName", findPersonFullName(e));
              }}
            />
            <div className="fc w100 gap2x">
              <Select
                label={t("Full Name")}
                name="fullName"
                type="text"
                width="390px"
                multi={true}
                minFilterLength={1}
                options={persList}
                makeEffect={option => {
                  var personaId = option?.value;
                  setFieldValue("personaId", findPersonFullName(personaId));
                  const client = clients.find(client => client.persona.personaId === personaId);
                  if (!client) {
                    setIsClient(false);
                    setValues({
                      fullName: personaId,
                      personaId: personaId,
                      orgFullName: "",
                      orgShortName: "",
                      registrDate: "",
                    });
                  }
                  if (client) {
                    setValues({
                      fullName: personaId,
                      personaId: personaId,
                      orgFullName: client.orgStructure.orgStructureId,
                      orgShortName: client.orgStructure.orgStructureId,
                      registrDate: client.createdAt,
                    });

                    setIsClient(true);
                  }
                }}
              />
              {isClient && <span>{t("Client").toUpperCase()}</span>}
            </div>

            {isClient && <DateInput label={t("Registration Date")} name="registrDate" />}

            <div className="flex_container nowrap autolabel">
              <Select
                label={t("Office")}
                name="orgFullName"
                type="text"
                width="60%"
                multi={true}
                options={orgStructuresList}
                makeEffect={e => {
                  setFieldValue("orgShortName", onOfficeSelect(e?.value));
                  setFieldValue("orgStructureId", onOfficeSelect(e?.value));
                }}
              />

              <Select
                label={t("Office code")}
                name="orgShortName"
                type="text"
                width="60%"
                multi={true}
                minFilterLength={1}
                options={orgStructuresCodeList}
                makeEffect={e => {
                  setFieldValue("orgFullName", onOfficeSelect(e?.value));
                }}
              />
            </div>
            {/* <TextAreaInput
                                label="Примітки по Персоні:" 
                                name="notes"
                                width="40%"
                            /> */}
            <div className="flex_container space-between">
              <FunctionButton
                name={t("Add person")}
                style={{left: "2em"}}
                onClick={() => {
                  navigate("/ba3a/admin/addPerson");
                }}
              />

              <FunctionButton type="submit" name={t("Register")} disabled={isClient} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddClient;
