export const CREDIT_OVERDRAFT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Offic",
    accessorKey: "office",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Counterparty Type",
    accessorKey: "counterpartyType",
    size: 90,
    maxSize: 90,
  },
  {
    header: "Counterparty Class",
    accessorKey: "counterpartyClass",
    size: 90,
    maxSize: 90,
  },
  {
    header: "Sum (from-to)",
    accessorKey: "fromToSum",
    size: 70,
    maxSize: 70,
  },
  {
    header: "% (from-to)",
    accessorKey: "fromToSumPersentage",
    size: 70,
    maxSize: 70,
  },
  {
    header: "Notes",
    accessorKey: "notes",
    size: 100,
    maxSize: 100,
    alignStart: true,
  },
]);
