import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import * as Yup from "yup";

import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import LogSettingsSectionBlock from "../../Logist/LogSettings/LogSettingsSectionBlock/LogSettingsSectionBlock";

import AccountHolderTypeModal from "./modals/AccountHolderTypeModal/AccountHolderTypeModal";
import AccountOpeningTab from "./modals/AccountOpeningModal/AccountOpeningTab";
import BalanceSheetAccountsTab from "./modals/BalanceSheetAccountsModal/BalanceSheetAccountTab";
import CreatingBatchModal from "./modals/CreatingBatchModal/CreatingBatchModal";
import CreditOverdraftTab from "./modals/CreditOverdraftTab/CreditOverdraftTab";
import CT_Type from "./modals/CT_Type/CT_Type";
import AccAssociationTab from "./tabs/AccAssociationTab/AccAssociationTab";

const FinSettings = () => {
  const {t} = useTranslation();
  const {addNewTabCustom} = useTabsContext();

  const firstColumnBlocks = [
    {
      headingNumber: 1,
      heading: "Balance sheet accounts",
      text: "Chart of Accounts",
      onClick: () => {
        addNewTabCustom({
          TabComponent: BalanceSheetAccountsTab,
          title: `1. ${t("Balance sheet accounts")}`,
        });
      },
      disabled: false,
      validateOnBlur: true,
    },
    {
      headingNumber: 2,
      heading: "Account opening",
      text: "The operation of opening",
      onClick: () => {
        addNewTabCustom({TabComponent: AccountOpeningTab, title: `2. ${t("Account opening")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 3,
      heading: "Creating a batch posting template",
      text: "Creation of a package",
      onClick: () => {
        addNewTabCustom({TabComponent: CreatingBatchModal, title: `3. ${t("Creating a batch posting template")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 4,
      heading: "Type of Account Holder (AH)",
      text: "Create account holder type",
      onClick: () => {
        addNewTabCustom({TabComponent: AccountHolderTypeModal, title: `4. ${t("Type of Account Holder (AH)")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 5,
      heading: "Type of cash transactions (CT)",
      text: "Creating a type of cash transaction",
      onClick: () => {
        addNewTabCustom({TabComponent: CT_Type, title: `5. ${t("Type of cash transactions (CT)")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 6,
      heading: "Internal Accounts Association",
      text: "Creating an association for the Company's internal accounts.",
      onClick: () => {
        addNewTabCustom({
          TabComponent: AccAssociationTab,
          formInitialValues: {externalAccId: null, typeCanEdit: false},
          controlled: true,
          title: `6. ${t("Internal Accounts Association")}`,
        });
      },
      disabled: false,
    },
    {
      headingNumber: 7,
      heading: "Client-Bank file reading constructor",
      text: "fileReadingConstructorText",
      onClick: () => {},
      disabled: true,
    },
  ];

  const secondColumnBlocks = [
    {
      headingNumber: 8,
      heading: "Credit / overdraft",
      text: "creditOverdraftText",
      onClick: () => {
        addNewTabCustom({
          TabComponent: CreditOverdraftTab,
          title: `8. ${t("Credit / overdraft")}`,
        });
      },
      disabled: false,
      validateOnBlur: true,
    },
  ];

  return (
    <div className="fcCol h100 w100 pb">
      <div className="h100 grid3Col">
        <div className="borderRight paddingRight">
          {firstColumnBlocks.map(({headingNumber, heading, text, onClick, disabled}) => (
            <LogSettingsSectionBlock
              key={headingNumber}
              headingNumber={headingNumber}
              heading={heading}
              text={text}
              onClick={onClick}
              disabled={disabled}
            />
          ))}
        </div>
        <div className="paddingLeft paddingRight">
          {secondColumnBlocks.map(({headingNumber, heading, text, onClick, disabled}) => (
            <LogSettingsSectionBlock
              key={headingNumber}
              headingNumber={headingNumber}
              heading={heading}
              text={text}
              onClick={onClick}
              disabled={disabled}
            />
          ))}
        </div>
        <div className="borderLeft paddingLeft"></div>
      </div>
    </div>
  );
};

export default withTabs({
  isClosableTabs: true,
  label: "Settings (FinBlock)",
  // validateOnBlur: true,
  // validationSchema: Yup.object().shape({
  //   balanceSerialNumber: Yup.string().matches(/^\d{4}$/, "Must be 4 digits"),
  //   // .required("Required"),
  //   balanceSheetAccId: Yup.string().length(4, "Must be exactly 4 characters").matches(/^\d+$/, "Must be digits only"),
  //   // .required("This field is required")
  //   reserveCode: Yup.string().length(2, "Must be exactly 2 characters").matches(/^\d+$/, "Must be digits only"),
  //   // .required("This field is required")
  //   currencyId: Yup.string().length(3, "Must be exactly 3 characters").matches(/^\d+$/, "Must be digits only"),
  //   // .required("This field is required")
  // }),
})(FinSettings);
