import {useTranslation} from "react-i18next";

import {Select} from "../../../../../Inputs/SelectField";

const CounterPartyLine = ({namePart}) => {
  const {t} = useTranslation();

  const officeOptions = [];
  const counterpartyTypeOptions = [];
  const counterpartyClassOptions = [];

  return (
    <div className="row gap">
      <Select name={`officeId${namePart}`} label={t("Offic")} options={officeOptions} autolabel />
      <Select
        name={`counterpartyTypeId${namePart}`}
        label={t("Counterparty Type")}
        options={counterpartyTypeOptions}
        autolabel
      />
      <Select
        name={`counterpartyClassId${namePart}`}
        label={t("Counterparty Class")}
        options={counterpartyClassOptions}
        autolabel
      />
    </div>
  );
};

export default CounterPartyLine;
