import React from "react";

import {ContextMenu, ContextMenuOption} from "../../../hook/useContextMenu";

const BankClientContextMenu = ({
  onAccountAssociationBtnClick,
  onToTheClientBalanceBtnClick,
  onConversionBtnClick,
  onCheckTransactionsBtnClick,
  onConductBtnClick,
  onCreateDebtBtnClick,
  menuVisible,
  menuPosition,
  currentBankTransactionId,
  hasCurrentTranscationAssociation,
}) => {
  return (
    <ContextMenu menuVisible={menuVisible} menuPosition={menuPosition}>
      <ContextMenuOption
        text={"Account association"}
        onClick={onAccountAssociationBtnClick}
        disabled={!currentBankTransactionId || hasCurrentTranscationAssociation}
      />
      <ContextMenuOption
        text={"To the Client`s balance"}
        onClick={onToTheClientBalanceBtnClick}
        disabled={!currentBankTransactionId || !hasCurrentTranscationAssociation}
      />
      <ContextMenuOption text={"Conversion"} onClick={onConversionBtnClick} disabled />
      <ContextMenuOption text={"Check transactions"} onClick={onCheckTransactionsBtnClick} disabled />
      <ContextMenuOption text={"Conduct"} onClick={onConductBtnClick} disabled />
      <ContextMenuOption text={"Create debt"} onClick={onCreateDebtBtnClick} disabled />
    </ContextMenu>
  );
};

export default BankClientContextMenu;
