import {useDispatch} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {fetchData} from "../../../../../../hook/axios.hook";
import editIcon from "../../../../../../images/icons/edit.png";
import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {getInfo} from "../../../../../../utilities/toasts";
import {TextAreaInput, TextInput} from "../../../../../Inputs";
import {fetchExternalAccounts} from "../../../FinSettingsSlice";

import AccountNumber from "./AccountNumber";
import ExternalAccountTypesSelect from "./ExternalAccountTupesSelect";

const ExtIntAccPart = () => {
  const {
    values: {typeCanEdit, externalAccountId, accType},
    setFieldValue,
  } = useFormikContext();
  const dispatch = useDispatch();

  const setEditMode = () => {
    setFieldValue("typeCanEdit", true);
  };

  const onTypeChange = () => {
    if (!accType) return;
    fetchData(`finance/externalAccounts/update/${externalAccountId}`, "patch", {accountTypeId: accType})
      .then(() => {
        getInfo("updated");
        dispatch(fetchExternalAccounts());
        setFieldValue("typeCanEdit", false);
      })
      .catch(() => {});
  };
  return (
    <div className="fcCol w100 aistr">
      <span className="mb">{t("External / Internal accounts №№")}</span>
      <div className="row gap mb0_5">
        <TextInput
          name="bank"
          label={t("Bank")}
          autolabel
          readOnly
          generalClassName="fGrow"
          style={{minWidth: "9rem"}}
        />
        <TextInput
          type="number"
          name="mfi"
          label={t("MFI")}
          autolabel
          inputClassName="border inputTypeNumberNoArrows ml5"
          width="6rem"
          readOnly
        />
        <TextInput
          name="externalAccNumber"
          inputClassName="border leftPlaceholder"
          placeholder={t("External account № ")}
          width="25.9rem"
          readOnly
        />
      </div>
      <div className="row jcsb">
        <ExternalAccountTypesSelect readOnly={!typeCanEdit} />
        <FunctionButton
          name={
            <span style={{display: "flex", alignItems: "center", flexWrap: "nowrap"}}>
              {typeCanEdit ? <span>Ok</span> : <img src={editIcon} style={{width: "1.5em", height: "1.5em"}} />}
            </span>
          }
          width={"2.5em"}
          onClick={typeCanEdit ? onTypeChange : setEditMode}
          disabled={!externalAccountId}
        />
        <AccountNumber disabled accountName={"externalAccount"} />
      </div>

      <TextAreaInput name="internalAccNaming" readOnly placeholder={"Account name"} height="2.5rem" />
    </div>
  );
};

export default ExtIntAccPart;
