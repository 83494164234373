import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import {DateInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import TextInput from "../../../Inputs/TextInput";

const HeaderInputRow = ({headerInputNames}) => {
  const {t} = useTranslation();

  return (
    <div className="fc jcsb nowrap w100 pt g2">
      {headerInputNames.map(el => {
        switch (el?.type) {
          case "select":
            return (
              <Select
                key={el.name}
                name={el.name}
                options={el.options}
                label={t(el.label)}
                readOnly={el?.readOnly || true}
              />
            );
          case "date":
            return <DateInput key={el.name} name={el.name} label={t(el.label)} readOnly={el?.readOnly || true} />;
          default:
            return (
              <TextInput
                key={el.name}
                name={el.name}
                label={t(el.label)}
                width={el.width ?? null}
                autolabel={el.autolabel}
                readOnly={el?.readOnly || true}
                hasTitle={el?.hasTitle || false}
              />
            );
        }
      })}
    </div>
  );
};

HeaderInputRow.propTypes = {
  headerInputNames: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      autolabel: PropTypes.bool,
    }).isRequired,
  ),
};

export default HeaderInputRow;
