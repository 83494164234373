import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";

import CounterPartyLine from "./CounterPartyLine";

const CreditParams = ({onPauseCreditBtnClick, onSaveCreditBtnClick}) => {
  const {t} = useTranslation();

  return (
    <div className="fcCol gap aistr">
      <div className="header asfs">{`2. ${t("Credit params")}`}</div>
      <div className="italic">{t("Lending parameters are set")}</div>
      <CounterPartyLine namePart="Credit" />

      <div className="row gap jcfe">
        <FunctionButton name="Pause" autoWidth disabled onClick={onPauseCreditBtnClick} />
        <FunctionButton name="Save" autoWidth disabled onClick={onSaveCreditBtnClick} />
      </div>
    </div>
  );
};

export default CreditParams;
