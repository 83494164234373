import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Field, useFormikContext, withFormik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import {MoneyInput, NumberInput, PriceInput, TextInput} from "../../../Inputs";
import FieldWithSample from "../../../Inputs/FieldWithSample";
import {Select} from "../../../Inputs/SelectField";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {DISCOUNT_TABLE_COLUMNS, MAIN_SERVICE_AND_DISCOUNT_TABLE_COLUMNS, SERVICE_TABLE_COLUMNS} from "./utils";

export const ServicesAndDiscountsBase = () => {
  const {t} = useTranslation();

  const discountTableColumns = useColumns(DISCOUNT_TABLE_COLUMNS);
  const serviceTableColumns = useColumns(SERVICE_TABLE_COLUMNS);
  const mainServicesAndDiscountsTableColumns = useColumns(MAIN_SERVICE_AND_DISCOUNT_TABLE_COLUMNS);

  const [isCheckedDiscountType, setIsCheckedDiscountType] = useState(false);
  const [isCheckedService, setIsCheckedService] = useState(false);

  const handleDiscountTypeChange = () => {
    setIsCheckedDiscountType(!isCheckedDiscountType);
  };

  const handleServiceChange = () => {
    setIsCheckedService(!isCheckedService);
  };

  const onAddDiscountsBtnClick = () => {};
  const onDeleteDiscountsBtnClick = () => {};

  const onAddDiscountsTypeBtnClick = () => {};
  const onDeleteDiscountsTypeBtnClick = () => {};

  return (
    <div className="pt">
      <div className="fdr gap mb aistr">
        <div className="fcCols gap0_5">
          <div className="fdr gap aibl">
            <Field type="radio" name="selection" value="cofNumber" />
            <TextInput label={t("CO(f) additional")} name="cof" width="13em" />

            <div className="fdr aife gap padding5" style={{border: "1px solid #bdd2f1"}}>
              <MoneyInput label={t("Discount")} name="discount" width="6em" />
              <div style={{width: "6em"}}>{t("or discount")}</div>
              <NumberInput name="discount" width="4.5em" className="procent" min={0} max={80} />
            </div>
          </div>
          <div className="fdr gap aibl">
            <Field type="radio" name="selection" value="factory" />
            <Select options={[]} label={t("Factory")} name="factoryId" />
            <div className="fdr aife gap padding5" style={{border: "1px solid #bdd2f1"}}>
              <MoneyInput label={t("Discount")} name="discount" width="6em" />

              <div style={{width: "6em"}}>{t("or discount")}</div>
              <NumberInput name="discount" width="4.5em" className="procent" min={0} max={80} />
            </div>
          </div>
          <div className="row jcfe gap3x w100 mt5 mb0_5">
            <FunctionButton name="Delete" disabled onClick={onDeleteDiscountsBtnClick} />
            <FunctionButton name="Add" disabled onClick={onAddDiscountsBtnClick} />
          </div>

          <div className="fdr gap aibl">
            <Field name="" type="checkbox" checked={isCheckedDiscountType} onChange={handleDiscountTypeChange} />
            <Select options={[]} label={t("Disc type")} name="discountType" />
            <div className="fdr aife gap padding5" style={{border: "1px solid #bdd2f1"}}>
              <MoneyInput label={t("Discount")} name="discount" width="6em" />

              <div style={{width: "6em"}}>{t("or discount")}</div>
              <NumberInput name="discount" width="4.5em" className="procent" min={0} max={80} />
            </div>
          </div>
          <div className="row jcfe gap3x w100 mt5">
            <FunctionButton name="Delete" disabled onClick={onDeleteDiscountsTypeBtnClick} />
            <FunctionButton name="Add" disabled onClick={onAddDiscountsTypeBtnClick} />
          </div>
        </div>
        <div className="fGrow" style={{width: "49%"}}>
          <ReactTable
            columns={discountTableColumns}
            defaultData={[]}
            onSelect={() => {}}
            enableMultiSelection={true}
            className="scrollX scrollY"
            tableClassName="fGrow"
          />
        </div>
      </div>
      <Divider />
      <div className="fdr gap">
        <div className="fcCols gap0_5">
          <div className="fdr" style={{gap: "5.5em"}}>
            <TextInput label={t("Product country")} name="country" labelStyles={{width: "9em"}} width="13em" />
            <TextInput label={t("Delivery term")} name="deliveryTerm" labelStyles={{width: "9em"}} width="13em" />
          </div>
          <div className="fdr gap ">
            <NumberInput label="~∑ (m3):" name="fieldM3" width="8.8em" disabled />
            <NumberInput label="~∑ (kg):" name="fieldKg" width="8.8em" disabled />
            <MoneyInput label="~∑ (€):" name="fieldEvr" width="8.8em" />
          </div>
          <div className="row jcfe w100 mb0_5 mt5">
            <FunctionButton name="Save" disabled />
          </div>
          <div className="fdr gap w100">
            <div className="fdr gap aic fGrow">
              <Field name="" type="checkbox" checked={isCheckedService} onChange={handleServiceChange} />
              <Select
                label={t("Service")}
                name="deliveryTerm"
                labelStyles={{width: "17em"}}
                options={[]}
                generalClassName="fGrow"
              />
            </div>
            <MoneyInput label={t("Sum")} name="sum" width="8em" />
          </div>
          <div className="row jcfe gap3x w100 mb0_5 mt5">
            <FunctionButton name="Delete" disabled onClick={onDeleteDiscountsTypeBtnClick} />
            <FunctionButton name="Add" disabled onClick={onAddDiscountsTypeBtnClick} />
          </div>
          <FunctionButton name={t("Calculate")} style={{marginLeft: "auto"}} disabled />
        </div>
        <div className="fGrow" style={{width: "49%"}}>
          <ReactTable
            columns={serviceTableColumns}
            defaultData={[]}
            onSelect={() => {}}
            enableMultiSelection={true}
            className="fGrow scrollX scrollY fShrink"
            tableClassName="fGrow"
          />
        </div>
      </div>
      <Divider />
      <div className="fGrow">
        <ReactTable
          columns={mainServicesAndDiscountsTableColumns}
          defaultData={[]}
          onSelect={() => {}}
          className="fGrow scrollX scrollY fShrink mb"
          tableClassName="fGrow"
          style={{minHeight: "12.5em"}}
        />
      </div>

      <div className="fdr jcfe gap">
        <FunctionButton name={t("Save to CO(f)")} disabled />
      </div>
    </div>
  );
};

const ServicesAndDiscounts = withFormik({
  mapPropsToValues: () => ({sum: "", ammount: ""}),
  enableReinitialize: true,
})(ServicesAndDiscountsBase);

export default ServicesAndDiscounts;
