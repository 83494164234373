import React from "react";
import {Route, Routes} from "react-router-dom";

import Accounts from "../../Components/accounts/Accounts";
import AccountsList from "../../Components/finance/AccountsList";

const FinAccounting = () => {
  return (
    <Routes>
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/accdir" element={<AccountsList />} />
    </Routes>
  );
};

export default FinAccounting;
