// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanded-cell-content-copy-btn {
  cursor: pointer;
  margin: 0 5px;
  color: gray;
  border: 0;
  border-radius: 2px;
}
.expanded-cell-content-copy-btn:hover {
  color: rgb(176, 175, 175);
}`, "",{"version":3,"sources":["webpack://./src/Components/conteiners/reactTable/copyCellStyle.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;AACF;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".expanded-cell-content-copy-btn {\n  cursor: pointer;\n  margin: 0 5px;\n  color: gray;\n  border: 0;\n  border-radius: 2px;\n  &:hover {\n    color: rgb(176, 175, 175);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
