import React, {useEffect, useState} from "react";

import {fetchList} from "../../../../../../hook/axios.hook";
import {Select} from "../../../../../Inputs";

const ExternalAccountTypesSelect = ({readOnly, props}) => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    fetchList("/finance/externalAccounts/types/getAll").then(res => {
      console.log("res :>> ", res);
      setTypes(res);
    });
  }, []);

  const options = types?.map(type => ({
    title: type?.name,
    value: type?.accountTypeId,
  }));

  return <Select label={"Account Type"} {...props} readOnly={readOnly} name="accType" autolabel options={options} />;
};

export default ExternalAccountTypesSelect;
