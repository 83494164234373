import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../../../utilities/Divider/Divider";
import {getInfo} from "../../../../../../utilities/toasts";
import ReactTable from "../../../../../conteiners/ReactTable";
import {useTabsContext} from "../../../../../conteiners/tabs/useTabs";
import {TextAreaInput} from "../../../../../Inputs";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {fetchData, fetchList} from "./../../../../../../hook/axios.hook";
import TextInput from "./../../../../../Inputs/TextInput";
import {TRANSACTION_PACKAGE_COLUMNS, TRANSACTION_PACKAGE_GROUPS_COLUMNS} from "./constants";

const CreatePackage = ({
  transactionPackage,
  createNewPackage,
  setTransactionPackageId,
  data,
  addTemplate,
  deletePackage,
  savePackage,
  deleteLastTemplate,
  setCouldModalClose,
}) => {
  const {t} = useTranslation();
  const groupColumns = useColumns(TRANSACTION_PACKAGE_GROUPS_COLUMNS);
  const columns = useColumns(TRANSACTION_PACKAGE_COLUMNS);
  const {setFieldValue, setValues, values, dirty} = useFormikContext();
  const {setCouldTabClose} = useTabsContext();

  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [isPackageChecked, setPackageChecked] = useState(false);

  useEffect(() => {
    transactionPackage && setPackageChecked(transactionPackage?.isChecked);
  }, [transactionPackage]);

  useEffect(() => {
    if (transactionPackage && (values?.packageCode === "" || values?.paymentPurpose !== "" || values?.note !== "")) {
      setCouldTabClose(false);
    } else {
      setCouldTabClose(true);
    }
  }, [values, transactionPackage]);

  useEffect(() => {
    setFieldValue("packageName", transactionPackage?.packageName || "");
    setFieldValue("packageCode", transactionPackage?.packageCode || "");
    setCurrentGroupId(transactionPackage?.packageGroupId);
  }, [transactionPackage]);

  useEffect(() => {
    fetchList(`/finance/transactionTemplates/packages/groups/getAll`).then(res => {
      const data = res.map((group, index) => ({
        id: group.packageGroupId,
        number: index + 1,
        groupCode: "?",
        balanceNumber: "?",
        group: group.groupName,
      }));
      setGroups(data);
      setFilteredGroups(data);
    });
  }, []);

  const filterGroups = filterStr => {
    if (filterStr.length >= 3) {
      const filtered = groups.filter(group => group.group.toUpperCase().includes(filterStr.toUpperCase()));
      setFilteredGroups(filtered);
    } else {
      console.log("dvd");
      setFilteredGroups(groups);
    }
  };

  // type: debit/credit
  const defineBalance = (balanceSheetAccId, type) => {
    const value = balanceSheetAccId.replace(/[^0-9]/g, "");
    if (value === "" || value.length !== 4) {
      setFieldValue(`${type}BalanceType`, "");
      setFieldValue(`${type}BalanceName`, "");
      return;
    }
    fetchData(`/finance/balanceSheet/get/${value}`, "get", null, null, true)
      .then(res => {
        setFieldValue(`${type}BalanceType`, res.type.balanceTypeName);
        setFieldValue(`${type}BalanceName`, res.balanceName);
      })
      .catch(e => {
        getInfo("No such balance find");
        setFieldValue(`${type}BalanceType`, "");
        setFieldValue(`${type}BalanceName`, "");
      });
  };

  const addNewTamplate = () => {
    fetchData(`/finance/transactionTemplates/add/${transactionPackage.transactionPackageId}`, "post", {
      debitBalanceId: values.debitBalanceId,
      creditBalanceId: values.creditBalanceId,
      paymentPurpose: values.paymentPurpose,
      note: values.note,
    })
      .then(res => {
        addTemplate(res);
        setTransactionPackageId(res.transactionPackageId);
        setValues({
          ...values,
          debitBalanceId: "",
          creditBalanceId: "",
          paymentPurpose: "",
          note: "",
          debitBalanceType: "",
          debitBalanceName: "",
          creditBalanceType: "",
          creditBalanceName: "",
        });
      })
      .catch(() => {});
  };

  const checkPackage = transactionPackageId => {
    fetchData(`/finance/transactionTemplates/packages/check/${transactionPackageId}`, "patch")
      .then(res => {
        if (res.isChecked) {
          Swal.fire({
            title: t("Checked success"),
            text: "",
            icon: "info",
            confirmButtonText: t("Ok"),
            showCancelButton: false,
          });
          setPackageChecked(true);
        } else {
          Swal.fire({
            title: t("Error"),
            text: "",
            icon: "error",
            confirmButtonText: t("Ok"),
            showCancelButton: false,
          });
          setPackageChecked(false);
        }
      })
      .catch(() => {
        setPackageChecked(false);
      });
  };

  const addPackageDisabled =
    !transactionPackage ||
    !values?.debitBalanceName ||
    values?.debitBalanceName === "" ||
    !values?.creditBalanceName ||
    values?.creditBalanceName === "" ||
    !values?.creditBalanceName ||
    !values.paymentPurpose ||
    values.paymentPurpose === "";

  const savePackageDisabled =
    !transactionPackage ||
    !values?.packageName ||
    values?.packageName === "" ||
    values?.packageName === transactionPackage?.packageName ||
    !currentGroupId;

  const checkPackageDisabled = !transactionPackage || isPackageChecked;
  const ifAlreadySave =
    transactionPackage &&
    values?.packageName &&
    values?.packageName !== "" &&
    values?.packageName === transactionPackage?.packageName;

  return (
    <>
      <div className="w100">
        <div className="fc nowrap w100" style={{gap: "27px"}}>
          <div>
            <div className="fc nowrap mb0_5 g1">
              <FunctionButton
                name={"Create Package"}
                onClick={createNewPackage}
                style={{height: "3em", width: "6.5em"}}
              />
              <div>
                <label>{t("Select the Package affiliation group:")}</label>
                <TextInput
                  name={"groupFilter"}
                  width="211px"
                  func={filterGroups}
                  placeholder={ifAlreadySave ? t(`Package already saved`) : t(`start entering minimum 3 digits`)}
                  readOnly={ifAlreadySave}
                />
              </div>
            </div>
            <div
              style={{
                pointerEvents: ifAlreadySave ? "none" : "auto",
              }}
            >
              <ReactTable
                defaultData={filteredGroups}
                columns={groupColumns}
                onSelect={setCurrentGroupId}
                current={currentGroupId}
                style={{height: "90px"}}
              />
            </div>
          </div>
          <div style={{flexGrow: "1"}}>
            <div className="fc nowrap w100 mb jcsb">
              <div style={{width: "50%"}}>
                <div className="label">{t("Рах. Дт")}</div>
                <div className="fc nowrap g1">
                  <TextInput
                    name={"debitBalanceId"}
                    width="6em"
                    className={`outlined ${!transactionPackage ? "readOnly" : ""}`}
                    func={v => defineBalance(v, "debit")}
                    handleInputChange={e => setFieldValue("debitBalanceId", e.target.value.replace(/[^0-9]/g, ""))}
                    readOnly={!transactionPackage || !currentGroupId}
                    maxLength={4}
                  />
                  <TextInput name={"debitBalanceType"} width="4em" className="outlined readOnly" readOnly />
                  <TextInput name={"debitBalanceName"} style={{flexGrow: "1"}} className="outlined readOnly" readOnly />
                </div>
              </div>
              <div style={{width: "49%"}}>
                <div className="label">{t("Рах. Кт")}</div>
                <div className="fc nowrap g1">
                  <TextInput
                    name={"creditBalanceId"}
                    width="6em"
                    className={`outlined ${!transactionPackage ? "readOnly" : ""}`}
                    func={v => defineBalance(v, "credit")}
                    handleInputChange={e => setFieldValue("creditBalanceId", e.target.value.replace(/[^0-9]/g, ""))}
                    readOnly={!transactionPackage || !currentGroupId}
                    maxLength={4}
                  />
                  <TextInput name={"creditBalanceType"} width="4em" className="outlined readOnly" readOnly />
                  <TextInput
                    name={"creditBalanceName"}
                    style={{flexGrow: "1"}}
                    className="outlined readOnly"
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="fc w100 jcsb nowrap g1 mb">
              <TextAreaInput
                name={"paymentPurpose"}
                placeholder="Enter Recognition of payment"
                areaHeight={"3em"}
                style={{height: "unset"}}
                required
                disabled={!transactionPackage || !currentGroupId}
              />
              <TextAreaInput
                name={"note"}
                placeholder={t("Add note")}
                areaHeight={"3em"}
                style={{height: "unset"}}
                disabled={!transactionPackage || !currentGroupId}
              />
            </div>
            <div className="fc w100 jcsb mb0_5">
              <FunctionButton name={"Add to the package"} disabled={addPackageDisabled} onClick={addNewTamplate} />
              <FunctionButton
                name={"Delete the last one"}
                disabled={!transactionPackage || data?.length === 0}
                onClick={deleteLastTemplate}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="w100">
        <ReactTable
          defaultData={data}
          columns={columns}
          onSelect={() => {}}
          current={null}
          className={"scrollX"}
          loading={false}
          style={{height: "100px"}}
        />
        <div className="fc nowrap jcsb g1 aife">
          <FunctionButton
            name={"Check the Package"}
            onClick={() => {
              checkPackage(transactionPackage.transactionPackageId);
            }}
            disabled={checkPackageDisabled || !currentGroupId}
            autoWidth
          />
          <TextAreaInput
            rows={1}
            name={"packageName"}
            placeholder={t("Enter the name of the Package")}
            areaHeight={"2em"}
            style={{height: "2.2em", flexGrow: "1"}}
            disabled={!transactionPackage || ifAlreadySave || !currentGroupId}
          />
          <TextInput
            name={"packageCode"}
            placeholder={"кодПакета"}
            width="6em"
            className="outlined readOnly"
            readOnly
          />
          <FunctionButton
            name={"Save the package"}
            tooltipMessage={ifAlreadySave ? "Package already saved" : "Package name and Group must be selected"}
            onClick={() => {
              savePackage(transactionPackage.transactionPackageId, currentGroupId, values.packageName);
            }}
            disabled={savePackageDisabled}
            autoWidth
          />
          <FunctionButton name={"Delete a package"} autoWidth disabled={!transactionPackage} onClick={deletePackage} />
        </div>
      </div>
    </>
  );
};

export default CreatePackage;
