import {useTranslation} from "react-i18next";

import {TextAreaInput, TextInput} from "../../../Inputs";

const LoadingPlace = () => {
  const {t} = useTranslation();

  return (
    <div className="mb">
      <div className="fdr aibl mb0_5">
        <div>4.</div>
        {/* <Select
          label={t("Loading place") + ":"}
          options={loadingPlaceOptions}
          name="loadingPlaceyId"
          width="25.8em"
          autolabel
        /> */}
        <TextInput label={t("Loading place") + ":"} name="loadingPlace" autolabel width="25.8em" />
      </div>
      <TextAreaInput name="loadingPlaceField" width="100%" readOnly />
    </div>
  );
};

export default LoadingPlace;
