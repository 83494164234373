import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {CREDIT_OVERDRAFT_TABLE_COLUMNS} from "./constants";

const useCreditOverdraftTab = () => {
  //MiniOverDraftParams Part
  const onPauseMiniOverdraftBtnClick = () => {};
  const onRestoreMiniOverdraftBtnClick = () => {};
  const onDeleteMiniOverdraftBtnClick = () => {};
  const onSaveMiniOverdraftBtnClick = () => {};

  //CreditParams Part
  const onPauseCreditBtnClick = () => {};
  const onSaveCreditBtnClick = () => {};

  //table
  const columns = useColumns(CREDIT_OVERDRAFT_TABLE_COLUMNS);
  const defaultData = [];

  return {
    columns,
    defaultData,
    onPauseMiniOverdraftBtnClick,
    onRestoreMiniOverdraftBtnClick,
    onDeleteMiniOverdraftBtnClick,
    onSaveMiniOverdraftBtnClick,
    onPauseCreditBtnClick,
    onSaveCreditBtnClick,
  };
};

export default useCreditOverdraftTab;
