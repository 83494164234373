import {Field, useFormikContext} from "formik";
import {t} from "i18next";

import ToolTip from "../../utilities/ToolTip";

const CheckBox = ({disabled, name, className, style, tooltipMessage, onCheck = () => {}}) => {
  const {values, setFieldValue} = useFormikContext();
  const isChecked = values[name] || false;

  return (
    <ToolTip
      title={typeof tooltipMessage === "string" ? t(tooltipMessage) : tooltipMessage}
      style={{height: "fit-content"}}
      className={className}
    >
      <Field
        type="checkbox"
        name={name}
        style={{cursor: disabled ? "default" : "pointer", ...style}}
        disabled={disabled}
        checked={isChecked}
        onChange={event => {
          setFieldValue(name, event.target.checked);

          onCheck();
        }}
      />
    </ToolTip>
  );
};

export default CheckBox;
