import React from "react";
import {t} from "i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../../../Inputs";
import ClientsSelect from "../../../../../Inputs/preparedInputs/ClientsSelect";

const DirectiveCreation = ({
  onCreateDirectiveBtnClick = () => {},
  noCounterpartyAssociation,
  hasAssociation,
  isDirectiveCreated,
}) => {
  return (
    <div className="border padding0_5 l0 t0 row gap fitContentWidth aist">
      <div className="row aic gap2x">
        <div style={{whiteSpace: "nowrap"}} className="header fitContentWidth ">{`${
          !noCounterpartyAssociation ? "2. " : ""
        }${t("Crediting to the Client's balance")}`}</div>
        <TextInput name="clientNumber" label="№" autolabel width="7rem" />
        {/* <ClientsSelect label="№" autolabel width="7rem" /> */}
      </div>

      <FunctionButton
        name="Create directive"
        disabled={!hasAssociation || isDirectiveCreated}
        tooltipMessage={!hasAssociation ? "Create an association first" : ""}
        onClick={onCreateDirectiveBtnClick}
        width="9rem"
        style={{width: "9rem", height: "3rem", textAlign: "center"}}
        className="br"
      />
    </div>
  );
};

export default DirectiveCreation;
