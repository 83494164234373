import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import {getMeasurementUnits} from "../../../../selectors/skuSelectors";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {currencyOptions} from "../../../../utilities/currencyOptions";
import ReactTable from "../../../conteiners/ReactTable";
import IndeterminateCheckbox from "../../../conteiners/reactTable/IndeterminateCheckbox";
import ControlCheck, {CONTROL_CHECK_TYPES} from "../../../Inputs/controlCheck/ControlCheck";
import {Select} from "../../../Inputs/SelectField";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import SkuPriceInput from "../../SkuPriceInput";

import {SKU_PRICES_TABLE_COLUMNS} from "./constants";

export const PRICES_TYPES = Object.freeze({SKU_VED_FORM: "skuVedForm"});

const SkuPricesWithTable = ({mayEdit = false}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  // const [procents, setProcents] = useState([]);

  const measurementUnits = useSelector(getMeasurementUnits);

  const {values, setFieldValue} = useFormikContext();
  const {
    listPrice,
    minimumPrice,
    settlementPrice,
    // costPrice, retailPrice, discountPrice
  } = values;

  useEffect(() => {
    var proc = [100];
    proc.unshift((settlementPrice * 100) / minimumPrice);
    proc.unshift((listPrice * 100) / minimumPrice);
    // setProcents(proc);
  }, [listPrice, minimumPrice, settlementPrice]);

  // const getProcents = price => {
  //   return listPrice ? (price * 100) / listPrice : "";
  // };

  useEffect(() => {
    const mu = measurementUnits?.find(item => item?.unitId === values?.unitId);
    const muTranslate = language === "en" ? mu?.engName : mu?.name;
    setFieldValue("unit", muTranslate);
  }, [values?.unitId]);

  const columns = useColumns(SKU_PRICES_TABLE_COLUMNS);
  const defaultData = [];

  const eClientPriceCountryOptions = [];

  return (
    <div className="fcCol gap aist pb0_5 w100">
      <Select
        label={t("SKU price for sq.m")}
        name="currencyId"
        options={currencyOptions}
        width="7em"
        labelClassName="header"
        required
      />
      <div className="row  gap w100">
        <div>
          <div className="fcCol fGrow aistr">
            <SkuPriceInput
              id="1"
              label={t("listPrice") + "*:"}
              mayEdit={mayEdit}
              price={"listPrice"}
              priceProc={"%"}
              verify={false}
              control={"priceCheckUser"}
              noTable
            />
            <SkuPriceInput
              id="1"
              label={t("E. Client Price") + "*:"}
              mayEdit={mayEdit}
              price={"eClientPrice"}
              priceProc={"%"}
              verify={false}
              control={"priceCheckUser"}
              hasSelect
              selectName="eClientPriceCountry"
              selectOptions={eClientPriceCountryOptions}
              noTable
            />
            <SkuPriceInput
              id="5"
              mayEdit={mayEdit}
              label={t("Min price") + ":"}
              price={"minimumPrice"}
              priceProc={listPrice}
              verify={false}
              control={""}
              noTable
            />
            <SkuPriceInput
              id="6"
              mayEdit={mayEdit}
              label={t("Max price") + ":"}
              price={"maxPrice"}
              priceProc={listPrice}
              verify={false}
              control={""}
              noTable
            />
            <SkuPriceInput
              id="3"
              label={t("costPrice") + ":"}
              mayEdit={mayEdit}
              price={"costPrice"}
              priceProc={listPrice}
              verify={false}
              control={""}
              noTable
            />
            <SkuPriceInput
              id="4"
              mayEdit={mayEdit}
              label={t("Sale Price") + ":"}
              price={"retailPrice"}
              priceProc={listPrice}
              verify={false}
              control={""}
              noTable
            />
          </div>
        </div>
        <div className="fcCol fGrow aist gap jcsb pt5">
          <SkuPriceInput
            id="2"
            label={t("discPrice") + "*:"}
            price={"discountPrice"}
            priceProc={listPrice}
            verify={false}
            mayEdit={mayEdit}
            control={"settlementPriceCheckUser"}
            noTable
          />
          <ReactTable columns={columns} defaultData={defaultData} className="fGrow" tableClassName="fGrow" />
        </div>
      </div>

      <div className="fcCol aist">
        <ControlCheck
          name="pricesChangedBy"
          date="pricesChangeDate"
          initialChecked={false}
          type={CONTROL_CHECK_TYPES.CHANGED_BY}
        />
        <div className="fc nowrap aic">
          <IndeterminateCheckbox checked={!!values.priceChecker} readOnly style={{marginRight: "10px"}} />
          <ControlCheck name="dd" date="ddd" initialChecked={false} />
          <FunctionButton disabled name="Ok" autoWidth />
        </div>
      </div>
    </div>
  );
};

SkuPricesWithTable.propTypes = {mayEdit: PropTypes.bool};

export default SkuPricesWithTable;
