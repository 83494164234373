import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormikContext} from "formik";

import {fetchData} from "../../../../../../hook/axios.hook";
import ConnectIcon from "../../../../../../images/icons/link.png";
import DisconnectIcon from "../../../../../../images/icons/Link_Break.png";
import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../../../utilities/Buttons/ImageBtn";
import useSwal from "../../../../../../utilities/hooks/useSwal";
import {getSuccess} from "../../../../../../utilities/toasts";
import {fetchExternalAccounts} from "../../../FinSettingsSlice";

import formatAccountNumber from "./../../../../../../utilities/formatAccountNumber";
import ExtIntAccPart from "./ExtIntAccPart";
import PayerAccPart from "./PayerAccPart";

const AccAssociationAccounts = () => {
  const {
    values: {externalAccId, payerAccountId},
  } = useFormikContext();
  const [currentAssociatedAcc, setCurrentAssociatedAcc] = useState(null);
  const [btnMode, setBtnMode] = useState(null);
  const {confirm} = useSwal();
  const {setValues} = useFormikContext();
  const dispatch = useDispatch();
  const onLinkAccBtnClick = () => {
    if (externalAccId && payerAccountId) {
      fetchData(`/finance/externalAccounts/link/${externalAccId}/${payerAccountId}`, "put")
        .then(res => {
          getSuccess("Accounts linked!");
          fetchData(`/finance/externalAccounts/get/${externalAccId}`, "get").then(res => {
            setCurrentAssociatedAcc(res);
          });
          dispatch(fetchExternalAccounts());
        })
        .catch(() => {});
    }
  };
  const onUnlinkAccBtnClick = () => {
    if (externalAccId && payerAccountId) {
      fetchData(`/finance/externalAccounts/unlink/${externalAccId}`, "put")
        .then(res => {
          getSuccess("Accounts unlinked!");
          setCurrentAssociatedAcc(acc => ({...acc, payerAccount: null}));
          dispatch(fetchExternalAccounts());
        })
        .catch(() => {});
    }
  };

  const switchBtnMode = mode => {
    setBtnMode(prev => (prev === mode ? null : mode));
  };

  const onSaveExternalAccBtnClick = () => {};
  const onSavePayerAccBtnClick = () => {
    switch (btnMode) {
      case "link":
        onLinkAccBtnClick();
        break;
      case "unlink":
        confirm({confirmFunc: onUnlinkAccBtnClick});
        break;
    }
  };

  useEffect(() => {
    if (externalAccId)
      fetchData(`/finance/externalAccounts/get/${externalAccId}`, "get").then(res => {
        setCurrentAssociatedAcc(res);
      });
    else setCurrentAssociatedAcc(null);
  }, [externalAccId]);

  useEffect(() => {
    // console.log("currentAssociatedAcc :>> ", currentAssociatedAcc);
    setBtnMode(null);
    setValues(prev => ({
      ...prev,
      // bank: "",
      mfi: currentAssociatedAcc?.bankMfis || "",
      externalAccNumber: formatAccountNumber(currentAssociatedAcc?.iban) || "",
      internalAccNaming: currentAssociatedAcc?.internalAccount?.accountName || "",
      externalAccount: currentAssociatedAcc?.internalAccount?.accountNumber || null,
      externalAccountId: currentAssociatedAcc?.accountId || null,
      payerCounterpartyTypeId: currentAssociatedAcc?.payerAccount?.ownerType || "",
      clientNumber: currentAssociatedAcc?.payerAccount?.owner?.clientId || "",
      payerAccount: currentAssociatedAcc?.payerAccount?.accountNumber || "",
      payerAccountId: currentAssociatedAcc?.payerAccount?.accountId || null,
      payerAccNaming: currentAssociatedAcc?.payerAccount?.accountName || "",
      typeCanEdit: currentAssociatedAcc ? !currentAssociatedAcc.accountTypeId : false,
      accType: currentAssociatedAcc?.accountTypeId || null,
      // accType:
      // internalAccNaming:
    }));
  }, [currentAssociatedAcc]);

  return (
    <div className="row gap w100 mb">
      <ExtIntAccPart onSaveExternalAccBtnClick={onSaveExternalAccBtnClick} />
      <div className="fcCol jcc gap">
        <ImageButton
          disabled={!externalAccId || currentAssociatedAcc?.payerAccount || !payerAccountId}
          className={btnMode === "link" ? "active-action-button" : ""}
          src={ConnectIcon}
          onClick={() => switchBtnMode("link")}
          width={2.3}
          height={2.3}
          style={{padding: 0}}
        />
        <ImageButton
          disabled={!externalAccId || !payerAccountId || (!currentAssociatedAcc?.payerAccount && payerAccountId)}
          className={btnMode === "unlink" ? "active-action-button" : ""}
          src={DisconnectIcon}
          onClick={() => switchBtnMode("unlink")}
          width={2.3}
          height={2.3}
          style={{padding: 0}}
        />
      </div>
      <PayerAccPart disabled={currentAssociatedAcc?.payerAccount}>
        <FunctionButton name="Save" onClick={onSavePayerAccBtnClick} disabled={!btnMode} autoWidth />
      </PayerAccPart>
    </div>
  );
};

export default AccAssociationAccounts;
