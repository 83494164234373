import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import {isEqual} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import translit from "ua-en-translit";
import * as Yup from "yup";

import plusIcon from "../../../images/icons/plus.png";
import saveImg from "../../../images/icons/save.png";
import {getCurrentProject} from "../../../selectors/generalSelectors";
import {BUTTON_TYPES} from "../../../utilities/Buttons/constants";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {dateFormat} from "../../../utilities/dateFormat";
import {getInfo} from "../../../utilities/toasts";
import {DateInput} from "../../Inputs";
import ImgFilePreview from "../../Inputs/fileSelect/preview/ImgFilePreview";
import {Select} from "../../Inputs/SelectField";
import {addEinfo, addEinfoFile, delEinfoFile, getDocsLoading, getProjectEinfo, updateEinfo} from "../ProjectSlice";

import {RegistrButton} from "./utils/RegistrButton";

const AddScreenShotsForm = ({setFile, files, forcedUpdate, docRef, focusOnDoc}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (current) setFile(current.docsArray);
  }, [current]);

  const today = useMemo(() => {
    return moment().format("YYYY-MM-DD");
  }, []);

  const initialValues = useMemo(() => {
    return {einfoId: "", note: "", dateFrom: today};
  }, []);

  const onUnpade = values => {
    const {dateFrom, note} = values;
    return dispatch(
      updateEinfo({
        einfoId: current.einfoId,
        data: {
          note,
          dateFrom,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        return res.payload.einfo;
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object({
        dateFrom: Yup.date().required([t("Enter date")]),
        note: Yup.string().required([t("Sreen info")]),
      })}
      onSubmit={(values, actions) => {
        if (current) {
          onUnpade(values).then(ein => {
            var {einfoId, note, dateFrom} = ein;
            setCurrent({einfoId, note, dateFrom});
            actions.setFieldValue("dateFrom", dateFrom);
            setFile(ein?.docsArray || []);
          });
        }
      }}
    >
      <DocForm
        setFile={setFile}
        files={files}
        docRef={docRef}
        focusOnDoc={focusOnDoc}
        forcedUpdate={forcedUpdate}
        setCurrent={setCurrent}
        current={current}
        dispatch={dispatch}
        onUnpade={onUnpade}
      />
    </Formik>
  );
};

const DocForm = props => {
  const {setFile, docRef, focusOnDoc, setCurrent, onUnpade, current, dispatch} = props;

  const today = useMemo(() => {
    return moment().format("YYYY-MM-DD");
  }, []);

  const {t} = useTranslation();

  const {values, resetForm, setFieldValue, setValues} = useFormikContext();

  const {einfoId, einfoNote} = values;

  const einfo = useSelector(getProjectEinfo),
    currentProject = useSelector(getCurrentProject),
    docsLoading = useSelector(getDocsLoading);

  const isDisabled = useMemo(() => !currentProject, [currentProject]);

  const registrList = useMemo(() => {
    var notes = [];
    einfo.forEach(einf => {
      const {einfoId, note, dateFrom} = einf;
      notes.push({id: einfoId, note, dateFrom: moment(dateFrom).format("DD.MM.YYYY")});
    });
    return notes;
  }, [einfo]);

  const noteList = useMemo(() => {
    return (
      einfo?.map(einfoItem => ({
        value: einfoItem.einfoId,
        title: einfoItem.note,
        einfoNote: einfoItem.note,
        einfoNoteId: einfoItem.einfoId,
      })) || []
    );
  }, [einfo]);

  useEffect(() => {
    if (einfoNote?.length !== 0) {
      const currE = einfo.find(ei => ei.note === einfoNote && ei.annexId === values.noteId);
      setFieldValue("note", current?.note);

      setFieldValue("einfoId", currE?.einfoId);
    }
  }, [einfoNote]);

  const askForUpdate = () => {
    if (current && values.note) {
      const {docsArray, einfoId: o, einfoNoteId, ...restCurr} = current;
      const {einfoId: p, einfoNoteId: v1, einfoNote, ...restVal} = values;

      const eq = isEqual(restVal, restCurr);

      if (!eq) {
        Swal.fire({
          title: "",
          text: `${t("Save screen")} ${values.note}?`,
          icon: "question",
          confirmButtonText: t("Yes"),
          showCancelButton: true,
          cancelButtonText: t("No"),
        }).then(answ => {
          if (answ.isConfirmed) {
            onUnpade(restVal);
          }
        });
      }
    }
  };

  useEffect(() => {
    askForUpdate();
    if (currentProject && einfo.length !== 0) {
      var einfo_ = [...einfo];
      var first = einfo_.pop();

      setFieldValue("einfoId", first.einfoId);
    } else {
      resetForm();
      setCurrent(null);
    }
  }, [currentProject]);

  useEffect(() => {
    if (einfoId && einfoId !== "") {
      askForUpdate();
      var currEinfo = einfo.find(item => item.einfoId === einfoId);

      if (currEinfo) {
        const {docsArray, ...rest} = currEinfo;
        setFile(docsArray);
        setCurrent(currEinfo);
        setValues({...rest});
      } else {
        setCurrent(null);
      }
    }
  }, [einfoId]);

  const onFileAdding = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file;
    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      if (current && current?.docsArray && current?.docsArray.length !== 0) {
        var exist = current?.docsArray.includes(`screenshots/screenshot_${einfoId}_${file.name}`);
        if (exist) {
          Swal.fire({
            title: "",
            text: `${[t("File name")]} ${file.name} ${[t("Already exist")]}`,
            icon: "warning",
            confirmButtonText: "Ок",
            customClas: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          continue;
        }
      }
      const filename = translit(file.name);
      formdata.append("screenshot", file, filename);
    }

    if (formdata.has("screenshot")) {
      dispatch(addEinfoFile({einfoId: current.einfoId, formdata})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setFile(res.payload.docsArray);
          setCurrent(item => {
            return {...item, docsArray: res.payload.docsArray};
          });
        }
      });
    }
  };

  const onFileDel = url => {
    dispatch(delEinfoFile({einfoId: current.einfoId, url})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setFile(res.payload.docsArray);
        setCurrent(item => {
          return {...item, docsArray: res.payload.docsArray};
        });
      }
    });
  };

  const onPlusClick = () => {
    if (currentProject) {
      resetForm();

      dispatch(
        addEinfo({
          projectId: currentProject.projectId,
          data: {
            dateFrom: today,
            note: t("note placeholder"),
          },
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          Swal.fire({
            title: "",
            text: `${t("Screenshot added")}`,
            icon: "info",
            confirmButtonText: "OK",
          });
          setCurrent(res.payload.einfo);
          setFieldValue("einfoId", res.payload.einfo.einfoId);
        }
      });
    } else {
      getInfo(t("Project at first"));
    }
  };

  const onFocus = i => {
    focusOnDoc(i);
    setFile(current?.docsArray);
  };

  return (
    <Form
      className="add-kp-doc-form"
      ref={el => {
        docRef.current[3] = el;
      }}
      onFocus={() => {
        onFocus(3);
      }}
    >
      <div className="number_block">
        <div>3.</div>
        <div className="block_content">
          <div className="screen_header">
            {t("Screen about")}
            <DateInput
              label="від:"
              name="dateFrom"
              flatpikrConfig={{
                minDate: moment().format("DD-MM-YYYY"),
              }}
              readOnly={isDisabled}
            />
          </div>

          <Select
            name="note"
            options={noteList}
            className="ref_input"
            question="Screen info"
            width="100%"
            readOnly={isDisabled}
          />

          <div className="cont">
            <ImageButton
              src={plusIcon}
              alt="add remind"
              width={1.2}
              height={1.2}
              tooltipMessage="Clear form"
              onClick={onPlusClick}
              disabled={isDisabled}
            />
            <ImageButton
              src={saveImg}
              alt="save"
              type={BUTTON_TYPES.SUBMIT}
              width={2}
              height={1.3}
              disabled={isDisabled}
            />
            <div className="jcsp">
              <RegistrButton label={"info"} list={registrList} fieldName={"note"} idFieldName="einfoId" />
            </div>
            <ImgFilePreview
              imgs={current?.docsArray || []}
              delFunc={onFileDel}
              accept={"image/*"}
              addFunc={onFileAdding}
              disable={!current}
              loading={docsLoading}
              necessaryCrop={false}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

AddScreenShotsForm.propTypes = {
  files: PropTypes.array,
  setFile: PropTypes.func,
  forcedUpdate: PropTypes.bool,
  docRef: PropTypes.any,
  focusOnDoc: PropTypes.func,
};

export default AddScreenShotsForm;
