import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import Swal from "sweetalert2";

import hideIcon from "../../../../images/icons/closeEye.png";
import showIcon from "../../../../images/icons/openEye.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {MoneyInput, NumberInput, TextAreaInput, TextInput} from "../../../Inputs";
import {useModal} from "../../../Modal/ModalProvider";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import CurencySelect from "../../../Project/ProjectDirectiveList/OrderDirectiveView/CurencySelect";
import useOrderDirectiveView from "../../../Project/ProjectDirectiveList/OrderDirectiveView/useOrderDirectiveView";
import Spinner from "../../../spinner/Spinner";
import {addNewFinOrder, deleteOrder, fetchFinOrder} from "../../FinSettings/FinSettingsSlice";

const CashdeskDirectiveOrder = ({orderId, viewOnly = false}) => {
  const [currentOrderId, setCurrentOrderId] = useState(orderId);
  const {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    currentTransactionId,
    onCreateOrder,
    // currentOrderId,
    onRunOrder,
    runOrderBtnDisabled,
    isOrderSaved,
    onCompleateOrder,
    isOrderCompleate,
    onTransactionSelect,
    pdfFile,
    checkExchangeRate,
    loading,
    currentOrder,
    onDeleteOrder,
  } = useOrderDirectiveView(currentOrderId);
  const blockOkBtn = viewOnly || isOrderCompleate || isOrderSaved || upperTableData?.length > 0;
  const [file, setFile] = useState(null);
  const {closeModal} = useModal();
  const dispatch = useDispatch();

  const generateDirectiveOrderPdfLink = pdfFile => {
    return new Promise(resolve => {
      pdfFile.getBlob(blob => {
        const fileUrl = URL.createObjectURL(blob);
        resolve(fileUrl);
      });
    });
  };
  const onSetFileUrl = pdfFile => {
    generateDirectiveOrderPdfLink(pdfFile).then(fileUrl => {
      setFile(fileUrl);
    });
  };

  useEffect(() => {
    if (pdfFile) {
      onSetFileUrl(pdfFile);
    }
  }, [pdfFile]);

  const onShowFile = () => {
    if (!pdfFile) onPrintOrderBtnClick();
    else if (pdfFile && !file) {
      onSetFileUrl(pdfFile);
    } else if (pdfFile && file) {
      setFile(null);
    }
  };

  const onPrintClick = () => {
    if (!pdfFile) {
      const pdf = onPrintOrderBtnClick();
      pdf.print();
    } else {
      pdfFile.print();
    }
  };

  const createNewOrder = directiveId => {
    dispatch(addNewFinOrder({directiveId, body: {typeId: 2}})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        const order = res.payload.directive.orders.at(-1)?.orderId;
        setCurrentOrderId(order);
        dispatch(fetchFinOrder(order));
      }
    });
  };

  return (
    <div className="fcCol h100 w100 pt5 aist">
      {loading && <Spinner />}

      <div
        className="grid2ColFirstBigger fGrow pb fShrink maxH100"
        style={{gridTemplateColumns: "minmax(0px, 1fr) 30%"}}
      >
        <div className="fcCol fGrow2 gap">
          <div className={"fc nowrap g1"}>
            <CurencySelect
              name="paymentCurrency"
              title="Payment currency"
              required
              makeEffect={e => checkExchangeRate(e?.value, true)}
              readOnly={blockOkBtn}
            />
            <MoneyInput
              name="paymentSumm"
              width="7em"
              label={t("Payment Sum")}
              required
              disabled={false}
              currency={null}
              placeholder={""}
              readOnly={blockOkBtn}
            />
            <NumberInput
              name="exchangeRate"
              width="6em"
              label={t("exchange rate") + " VAL / EUR:"}
              autolabel
              readOnly={true}
            />
            {!blockOkBtn && (
              <FunctionButton name="Ok" onClick={onRunOrder} disabled={!runOrderBtnDisabled} width={"3em"} />
            )}
          </div>
          <div className=" w100  " style={{border: "1px solid #A3B9D9", padding: "1em"}}>
            <div className="fdr gap jcsb">
              <div className="fdr aic gap " style={{marginRight: "2em"}}>
                <TextInput name="dt" label={t("Dt")} autolabel style={{width: "30em"}} readOnly={true} />
              </div>

              <div className="fdr aic gap" style={{marginLeft: "2em"}}>
                <TextInput name="kt" label={t("Kt")} autolabel style={{width: "30em"}} readOnly={true} />
              </div>
            </div>
            <div className="fdr aic gap">
              <ToolTip title="Dt account name" className="fGrow">
                <TextAreaInput name="debitAccountName" height="2.5em" placeholder={t("Dt account name")} disabled />
              </ToolTip>
              <ToolTip title="Kt account name" className="fGrow">
                <TextAreaInput name="creditAccountName" height="2.5em" placeholder={t("Kt account name")} disabled />
              </ToolTip>
            </div>
            <Divider />
            <div className="fdr aic gap">
              <ToolTip title="Payment purpose" className="fGrow2">
                <TextAreaInput
                  name="paymentPurpose"
                  height="4.5em"
                  placeholder="Enter Recognition of payment"
                  required
                  readOnly={true}
                />
              </ToolTip>
              <ToolTip title="Note (for the whole directive)" className="fGrow">
                <TextAreaInput
                  name="compleateNote"
                  height="4.5em"
                  placeholder={t("Add note")}
                  readOnly={!isOrderSaved || isOrderCompleate}
                />
              </ToolTip>
            </div>
          </div>
          <div className="fGrow w100 mw100 h100" style={{position: "relative"}}>
            <ReactTable
              columns={upperTableColumns}
              defaultData={upperTableData}
              current={currentTransactionId}
              onSelect={onTransactionSelect}
              className="mb scrollX"
            />
          </div>
          {!viewOnly && (
            <div className={"fc nowrap g1  jcfe w100"}>
              <FunctionButton
                name="Delete order"
                autoWidth
                disabled={
                  isOrderCompleate || !currentOrderId || (upperTableData?.length === 0 && lowerTableData?.length === 0)
                }
                onClick={() => {
                  const directiveId = currentOrder?.directiveId;
                  onDeleteOrder()
                    .then(res => {
                      if (res.meta.requestStatus === "fulfilled") {
                        createNewOrder(directiveId);
                      }
                    })
                    .catch();
                }}
              />
              <FunctionButton
                name="Create order"
                autoWidth
                disabled={!currentOrderId || upperTableData?.length === 0}
                onClick={onCreateOrder}
              />
            </div>
          )}
          <div className="fGrow w100 mw100 h100">
            <ReactTable
              columns={lowerTableColumns}
              defaultData={lowerTableData}
              current={currentTransactionId}
              onSelect={onTransactionSelect}
              className=" mb scrollX"
            />
          </div>
        </div>
        <div className="kpPreview__box pdfViewerContain w100 h100 fdc " style={{backgroundColor: "transparent"}}>
          <NewPdfViewer fileArray={file ? [file] : []} hiding={false} onlyLocalFile={true} format={"PDF"} />
          <ImageButton
            src={file ? showIcon : hideIcon}
            alt="hide doc"
            width={1.5}
            height={1.5}
            disabled={!isOrderCompleate}
            onClick={onShowFile}
            className="doc_view__visibility"
            style={{justifyContent: "left"}}
          />
          <div className="row jcsb w100 mt">
            <FunctionButton
              onClick={() => onCompleateOrder({noteWithoutMerge: true})}
              name="Run order"
              autoWidth
              disabled={!isOrderSaved}
              tooltipMessage={
                isOrderCompleate ? "Order is already compleated" : !isOrderSaved ? "Order must be saved" : ""
              }
            />
            <FunctionButton onClick={() => closeModal()} name="Exit" autoWidth />
            <FunctionButton
              onClick={onPrintClick}
              name="Print an order"
              autoWidth
              disabled={!isOrderCompleate}
              tooltipMessage={isOrderCompleate ? null : "Order must be saved and completed"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({}),
})(CashdeskDirectiveOrder);
