import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import useFileFolderPicker from "../../../hook/useFileFolderPicker";
import {dateFormat} from "../../../utilities/dateFormat";
import formatAccountNumber from "../../../utilities/formatAccountNumber";
import {numberFormat} from "../../../utilities/moneyFormat";
import {useModal} from "../../Modal/ModalProvider";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  fetchAllBankTransactions,
  fetchBankTransaction,
  selectBankTransactions,
  selectFinBlockLoading,
  uploadBankClientStatements,
} from "../FinBlockSlice";
import {clearStatementDirective} from "../FinSettings/FinSettingsSlice";

import {swalCustom} from "./../../../utilities/hooks/swalCustom";
import AccountAssociationAndTransaction from "./modals/AccountAssociationAndTransaction/AccountAssociationAndTransaction";
import ViewAccountAssociationAndTransaction from "./modals/AccountAssociationAndTransaction/ViewAccountAssociationAndTransaction";
import {formTransactionStatus} from "./BankClient";
import {BANK_CLIENT_TABLE_COLUMNS} from "./constants";

const useBankClient = () => {
  const bankOptions = [];
  const currencyOptions = [];

  const {openModal} = useModal();

  const {files, handleFolderUpload, folderPickerRef, clearFilesArray} = useFileFolderPicker();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const loading = useSelector(selectFinBlockLoading);
  const bankTransactions = useSelector(selectBankTransactions);

  const [currentBankTransactionId, setCurrentBankTransactionId] = useState(null);
  const currentBankTransaction = bankTransactions?.find(
    ({transactionId}) => transactionId === currentBankTransactionId,
  );
  const hasCurrentTranscationAssociation = !!currentBankTransaction?.isFullfiled;

  const bankClientTableData = useMemo(
    () =>
      bankTransactions?.map((transaction, index) => {
        const isDebit = Boolean(transaction?.bankClientStatement?.isDebit);
        return {
          id: transaction?.transactionId,
          number: ++index,
          trId: transaction?.bankClientStatement?.transactionUuid,
          accountNumber: formatAccountNumber(transaction?.[isDebit ? "debitAccount" : "creditAccount"]?.accountNumber),
          counterpartyAccountNumber: formatAccountNumber(
            transaction?.[isDebit ? "creditAccount" : "debitAccount"]?.accountNumber,
          ),
          requisitesC: transaction?.bankClientStatement?.counterpartyEdrpou,
          counterpartyName: transaction?.bankClientStatement?.counterparty,
          commissionsAmmount: transaction?.bankClientStatement?.feesSum,
          counterparty: transaction?.bankClientStatement?.counterparty,
          date: dateFormat(transaction?.bankClientStatement?.dateAndTime),
          status: formTransactionStatus(transaction),
          operationType: "?",
          costsDt: isDebit ? numberFormat(transaction?.sum) : "",
          revenueCt: isDebit ? "" : numberFormat(transaction?.sum),
          currency: "",
          paymentPurpose: transaction?.paymentPurpose,
          rate: transaction?.bankClientStatement?.rate,
          equivalent: transaction?.bankClientStatement?.nbuEquivalentSum,
          remainder: numberFormat(transaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
        };
      }) || [],
    [bankTransactions],
  );

  const bankClientTableColumns = useColumns(BANK_CLIENT_TABLE_COLUMNS);

  const onBankClientTableRowSelect = id => {
    setCurrentBankTransactionId(id);
  };

  useEffect(() => {
    dispatch(fetchAllBankTransactions());
  }, []);

  useEffect(() => {
    if (files?.length > 0) {
      const formData = new FormData();
      files.forEach(file => {
        formData.append("file", file);
      });
      dispatch(uploadBankClientStatements(formData)).then(resp => {
        clearFilesArray();
        if (resp.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllBankTransactions());
        }
      });
    }
  }, [files]);

  const onBankClientFilterChange = () => {};
  const onBankStatementsListFilterChange = () => {};

  const onGetDataBtnClick = () => {
    swalCustom.info({
      text: `${t("bank client folder pick")}\n *${t("folder pick warning")} `,
      noTitle: true,
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmFunc: () => folderPickerRef.current.click(),
    });
  };

  const onAccountAssociationBtnClick = () => {
    if (!currentBankTransactionId || hasCurrentTranscationAssociation) return;
    dispatch(fetchBankTransaction({id: currentBankTransactionId}));

    openModal(<AccountAssociationAndTransaction />, {
      label: t("Account association"),
      closable: true,
      hasAssociation: false,
      style: {height: "300px", minHeight: "300px"},
      onModalClose: () => {
        dispatch(clearStatementDirective());
      },
    });
  };
  const onToTheClientBalanceBtnClick = () => {
    if (!currentBankTransactionId || !hasCurrentTranscationAssociation) return;
    dispatch(fetchBankTransaction({id: currentBankTransactionId}));

    openModal(<AccountAssociationAndTransaction />, {
      label: t("Account association"),
      className: "largeModal",
      closable: true,
      hasAssociation: true,
      onModalClose: () => {
        dispatch(clearStatementDirective());
      },
    });
  };

  const onConversionBtnClick = () => {};
  const onCheckTransactionsBtnClick = () => {};
  const onConductBtnClick = () => {};
  const onCreateDebtBtnClick = () => {};

  return {
    bankOptions,
    currencyOptions,
    loading,
    onBankClientFilterChange,
    onBankStatementsListFilterChange,
    onGetDataBtnClick,
    bankClientTableColumns,
    bankClientTableData,
    folderPickerRef,
    handleFolderUpload,
    currentBankTransactionId,
    onBankClientTableRowSelect,
    onAccountAssociationBtnClick,
    onToTheClientBalanceBtnClick,
    onConversionBtnClick,
    onCheckTransactionsBtnClick,
    onConductBtnClick,
    onCreateDebtBtnClick,
    hasCurrentTranscationAssociation,
  };
};

export default useBankClient;
