import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import makeNameFromPersona from "../../../utilities/makeNameFromPersona";
import DateInput from "../DateInput";
import TextInput from "../TextInput";

import checkImg from "./buttonx.png";

export const CONTROL_CHECK_TYPES = Object.freeze({CONTROL: "control", CHANGED_BY: "changed by"});

const ControlCheck = ({
  name,
  date,
  initialChecked = false,
  readOnly = true,
  type = CONTROL_CHECK_TYPES.CONTROL,
  label,
  style = {},
}) => {
  const [checked, setChecked] = useState(initialChecked);
  const {t} = useTranslation();

  return (
    <div className="fc nowrap autolabel aibl" style={{gap: "0.3em", alignItems: "baseline", ...style}}>
      {!readOnly && (
        <input
          type="checkbox"
          checked={checked}
          // onChange={()=>{setChecked(i=>!i)}}
          onChange={() => {}}
          // readOnly={true}
          disabled
        />
      )}
      <TextInput
        label={label ? t(label) : type === CONTROL_CHECK_TYPES.CONTROL ? t("Control") : t("Changed by")}
        name={name}
        width={type === CONTROL_CHECK_TYPES.CONTROL ? "100%" : "152px"}
        readOnly={readOnly}
      />
      {/* <TextInput label={t("From")} name={date} width="8em" readOnly={readOnly} /> */}
      <DateInput label={t("From")} name={date} width="8em" readOnly={readOnly} />

      {!readOnly && <img src={checkImg} alt="" style={{height: "1.6em"}} draggable="false" />}
    </div>
  );
};

export default ControlCheck;
