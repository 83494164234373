import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import {debounce, isEqual} from "lodash";
import Swal from "sweetalert2";

import editIcon from "../../../../../images/icons/edit.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {MoneyInput, NumberInput, PriceInput, TextInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";
import MoneyReadInput from "../../../../Inputs/MoneyReadInput";

const SumBlockUpdated = ({readOnly, initialLine}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const {values, setValues, resetForm, setFieldValue} = useFormikContext();

  const {
    values: {amount, listPrice, retailPrice, discount, topDiscount, topDiscountCheck, discountCheck},
  } = useFormikContext();

  const [summ, setSumm] = useState(null),
    [total, setTotal] = useState(null),
    [toPayment, setToPayment] = useState(null);

  const initialValues = {
    listPrice: null,
    retailPrice: null,
    sku: {minimumPrice: null},
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isEqual(initialValues, values)) {
        setValues(initialLine);
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [initialValues, values]);

  useEffect(() => {
    var initPrice = retailPrice;
    //  || listPrice;
    var sum_ = +initPrice * (amount || 1);
    setSumm(sum_);
  }, [amount, retailPrice, listPrice]);

  useEffect(() => {
    setTotal(discountCheck && discount ? summ - (summ * discount) / 100 : summ);
  }, [summ, discount, discountCheck]);

  useEffect(() => {
    setToPayment(topDiscountCheck && topDiscount ? total - (total * topDiscount) / 100 : total);
  }, [summ, total, topDiscount, topDiscountCheck]);

  useEffect(() => {
    const translatedMeasurUnit =
      language === "en" ? values?.sku?.measurementUnit?.engName : values?.sku?.measurementUnit?.name;
    setFieldValue("measurUnit", translatedMeasurUnit);
  }, []);

  const [isUpdatingDiscount, setIsUpdatingDiscount] = useState(false);
  const [isUpdatingDiscountPrice, setIsUpdatingDiscountPrice] = useState(false);

  const handlePriceBlur = () => {
    if (values?.discountPrice && !isUpdatingDiscount) {
      setIsUpdatingDiscount(true);

      const calculatedDiscount = values?.listPrice
        ? ((values?.retailPrice - values?.discountPrice) / values?.retailPrice) * 100
        : 0;

      if (calculatedDiscount >= 0 && calculatedDiscount !== values?.discount) {
        setFieldValue("discount", calculatedDiscount.toFixed(2));
      } else {
        setFieldValue("discount", "0");
      }

      setIsUpdatingDiscount(false);
    }
  };

  useEffect(() => {
    if (values?.discount && !isUpdatingDiscountPrice) {
      setIsUpdatingDiscountPrice(true);

      const calculatedDiscountPrice = values?.retailPrice ? values?.retailPrice * (1 - values?.discount / 100) : 0;

      if (calculatedDiscountPrice !== values?.discountPrice) {
        setFieldValue("discountPrice", calculatedDiscountPrice.toFixed(2));
      }

      setIsUpdatingDiscountPrice(false);
    }
  }, [values?.discount]);

  const saleLessThanMin = +values?.retailPrice < +values?.sku?.minimumPrice;
  const saleBiggerThanMax = +values?.retailPrice > +values?.sku?.maxPrice;

  const specLessThanMin =
    values?.discountCheck && values?.discount !== null && +values?.discountPrice < +values?.sku?.minimumPrice;
  const specBiggerThanMax = values?.discountCheck && +values?.discountPrice > +values?.sku?.maxPrice;

  return (
    <>
      <div className="summ-block_upd fcCol">
        <div className="summ-item aibl fdr" style={{gap: "7em"}}>
          <div className="fdr gap aibl">
            <PriceInput label="listPrice" name="listPrice" procent="100" width="7em" readOnly={true} required />
            <FunctionButton name="" width="4em" disabled>
              <div className="fdr aibl jcc gap2">
                <img src={editIcon} alt="pencil" draggable="false" width="18em" height="18em" />
                {/* / <div>Ok</div> */}
              </div>
            </FunctionButton>
          </div>

          <PriceInput
            label={t("RR price for client*")}
            name="retailPrice"
            width="6em"
            procent={values?.retailPrice && values?.listPrice ? (values.retailPrice * 100) / values.listPrice : 0}
            readOnly={true}
          />

          <PriceInput
            label={t("Min price")}
            name="sku.minimumPrice"
            width="6em"
            procent={
              values?.sku?.minimumPrice && values?.listPrice ? (values?.sku?.minimumPrice * 100) / values.listPrice : 0
            }
            readOnly={true}
          />

          <PriceInput
            label={t("Max price")}
            name="sku.maxPrice"
            width="6em"
            procent={values?.sku?.maxPrice && values?.listPrice ? (values?.sku?.maxPrice * 100) / values.listPrice : 0}
            readOnly={true}
          />
        </div>

        <div className="summ-item fdr gap aibl">
          <div className="fdr gap aife" style={{marginRight: "1em"}}>
            <MoneyInput label={t("Sale price")} name="retailPrice" width="8em" />
            <div>{t("for")}</div>
            <TextInput label="" name="measurUnit" width="4em" min={1} disabled />
          </div>

          <div className="fdr gap" style={{marginRight: "5em"}}>
            <TextInput label={t("Quantity")} name="amount" type="number" width="4em" min={1} readOnly={readOnly} />
            <TextInput label="" name="measurUnit" width="4em" min={1} disabled />
          </div>

          <MoneyReadInput label={t("Sum")} name="summ" rawValue={summ} readOnly={true} width="8em" />

          <FieldWithSample
            name="discountCheck"
            label={t("Special price")}
            style={{display: "flex", alignItems: "end", gap: "2em"}}
          >
            <div className="fdr aife gap2">
              <MoneyInput label="" name="discountPrice" width="6em" onBlur={handlePriceBlur} />
              <div>{t("for")}</div>
              <TextInput label="" name="measurUnit" width="4em" min={1} disabled />

              <div style={{width: "6em"}}>{t("or discount")}</div>
              <NumberInput
                name="discount"
                width="4.5em"
                className="procent"
                // onBlur={handleProcentBlur}
                maxNumberLength={2}
              />
            </div>
          </FieldWithSample>

          <MoneyReadInput
            label={t("Special sum")}
            name="total"
            rawValue={values?.discountPrice && values?.discountCheck ? total : null}
            width="8em"
          />
        </div>
        {saleLessThanMin && <div style={{color: "#b72025"}}> {t("Sell price is less than minimum price")}</div>}
        {saleBiggerThanMax && <div style={{color: "#b72025"}}> {t("Sell price is higher than maximum price")}</div>}
        {specLessThanMin && <div style={{color: "#b72025"}}>{t("Special price is less than minimum price")}</div>}
        {specBiggerThanMax && <div style={{color: "#b72025"}}> {t("Special price is higher than maximum price")}</div>}
      </div>
    </>
  );
};

export default SumBlockUpdated;
