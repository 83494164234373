export const SKU_PRICES_TABLE_COLUMNS = Object.freeze([
  //   {
  //     header: "№пп",
  //     accessorKey: "number",
  //     // size: 50,
  //   },
  {
    header: "Discount",
    accessorKey: "discount",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },

  {
    header: "Term",
    accessorKey: "term",
    size: 100,
    maxSize: 100,
    isSortable: true,
  },

  {
    header: "cond",
    accessorKey: "conditions",
    size: 250,
    maxSize: 250,
  },
]);
