import {useTranslation} from "react-i18next";
import {Field, useFormikContext} from "formik";

import {ReactComponent as RemindImg} from "../../../../../images/icons/alarmClock.svg";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../../utilities/ToolTip";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, NumberInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";
import ToDoSection from "../ToDoSection";

import useCoSection from "./useCoSection";

const CoSection = () => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {
    coTableColumns,
    coTableData,
    onCoTableRowSelect,
    currentCoId,
    onCreateCoBtnClick,
    onSaveCoBtnClick,
    onDeleteCoBtnClick,
    coManagerOptions,
    designerOptions,
    designerTypeOptions,
    coAgentOptions,
    agentTypeOptions,
    projectId,
    onDesignerSelect,
    onAgentSelect,
    hasCoChanged,
    remindBtnColor,
    coLoading,
    isDoneDisabled,
  } = useCoSection();

  return (
    <div className="right-side" style={{maxHeight: "100%"}}>
      <div>
        <div className="fdr gap">
          <Select
            label={t("CO Manager")}
            name="managerCoId"
            options={coManagerOptions}
            width="15em"
            autolabel
            disabled={!!currentCoId || !projectId}
          />
          <div className="flex_container jcsb  w100">
            <DateInput label={t("From")} name="startDate" fromCurrentDate disabled={!!currentCoId || !projectId} />
            <DateInput
              label={t("To")}
              name="finalDate"
              fromCurrentDate
              disabled={!!currentCoId || !projectId}
              onChange={e => {
                console.log(e.target.value);
              }}
            />
            <div className="row aic gap">
              <div className={`btn__upload remindBtn--${remindBtnColor ?? "blue"}`} width={4} height={1.3}>
                <RemindImg />
              </div>

              <div className="row gap">
                <Field name="isDone" type="checkbox" disabled={!currentCoId || isDoneDisabled} /> {t("CO is ready")}
              </div>
            </div>
          </div>
        </div>
        <div className="fdr mb0_5 jcsb" style={{gap: "4em", marginTop: "0.5em"}}>
          <TextInput label={t("CO office") + ":"} name="coOffice" autolabel readOnly />
          <ToolTip title="Firstly fill in the fields: CO Manager, From, To" bottom>
            <FunctionButton
              name={t("Create CO")}
              onClick={onCreateCoBtnClick}
              disabled={!projectId || !!currentCoId || !values.managerCoId || !values.startDate || !values.finalDate}
            />
          </ToolTip>

          <TextInput label={t("CO №") + " :"} name="coNumber" autolabel readOnly />
        </div>

        <div className="fdr gap mb0_5">
          <NumberInput label={t("CO Sum") + ":"} name="coSum" autolabel readOnly />
          <NumberInput label={t("Advance") + "%:"} name="advance" autolabel readOnly width="5em" />
          <NumberInput label={t("Sum (n)") + ":"} name="planSum" autolabel readOnly />
          <NumberInput label={t("Sum (f)") + ":"} name="factSum" autolabel readOnly />
          <div className="row aibl">
            (
            <NumberInput label="" name="factSumPersent" readOnly width="4em" />
            )%
          </div>
        </div>
      </div>
      <div className="co_block mb0_5" style={{border: "1px solid #DAE1EC", padding: "0.5em"}}>
        <div className="row w100 gap">
          <Select
            label={t("Designer")}
            name="designer"
            options={designerOptions}
            width="14em"
            autolabel
            makeEffect={onDesignerSelect}
            onBlur={({setTextValue}) => {
              if (!values.designer) {
                setTextValue("");
              }
            }}
            disabled={!projectId || !currentCoId}
          />
          <NumberInput
            label={t("%%Designer") + " :"}
            name="designerPersent"
            width="4em"
            autolabel
            inputClassName="inputTypeNumberNoArrows"
            disabled={!projectId || !currentCoId}
          />
          <Select
            label={t("Designer type") + " :"}
            name="designerType"
            width="10em"
            options={designerTypeOptions}
            autolabel
            disabled={!projectId || !currentCoId}
          />
        </div>
        <div className="row w100 gap">
          <Select
            label={t("CO Agent")}
            name="agent"
            options={coAgentOptions}
            width="14.4em"
            autolabel
            makeEffect={onAgentSelect}
            onBlur={({setTextValue}) => {
              if (!values.agent) {
                setTextValue("");
              }
            }}
            disabled={!projectId || !currentCoId}
          />
          <NumberInput
            label={t("%%Agent") + " :"}
            name="agentPersent"
            width="4em"
            autolabel
            selectOnly
            disabled={!projectId}
            inputClassName="inputTypeNumberNoArrows"
          />
          <Select
            label={t("Agent type") + " :"}
            name="agentType"
            width="10em"
            options={agentTypeOptions}
            autolabel
            disabled={!projectId || !currentCoId}
          />
        </div>
      </div>
      <TextInput label={t("CO description")} name="coDescription" autolabel disabled={!projectId || !currentCoId} />
      <div className="fdr gap jcsb mb0_5 mt0_5">
        <span style={{width: "15em"}}></span>
        <FunctionButton
          name={t("Save CO")}
          onClick={onSaveCoBtnClick}
          disabled={!projectId || !currentCoId || hasCoChanged}
        />

        <FunctionButton name={t("Delete CO")} onClick={onDeleteCoBtnClick} disabled={!projectId || !currentCoId} />
      </div>
      <ReactTable
        defaultData={coTableData}
        columns={coTableColumns}
        current={currentCoId}
        className="fGrow scrollX"
        style={{height: "15em"}}
        loading={coLoading}
        onSelect={onCoTableRowSelect}
      />
      <ToDoSection coId={currentCoId} projectId={projectId} />
    </div>
  );
};

export default CoSection;
