import {useState} from "react";
import {t} from "i18next";

import Divider from "../../../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../../../conteiners/filters/Filter.hoc";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, MoneyInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

import useBankStatementsList from "./useBankStatementsList";

const BankStatementsList = () => {
  const BANK_STATEMENTS_LIST_WINDOW_NAME = "BankStatementsList";

  const {
    bankStatementsListColumns,
    bankStatementsListData,
    onBankStatementsListFilterChange,
    bankOptions,
    currencyOptions,
    loading,
  } = useBankStatementsList();

  const [current, setCurrent] = useState(null);

  return (
    <div className="fcCol h100 w100 pb">
      <SessionFiltersContainer
        window={BANK_STATEMENTS_LIST_WINDOW_NAME}
        onFilterChange={onBankStatementsListFilterChange}
      >
        <Filters>
          <TextInput
            name="trId"
            placeholder={t("tr.id")}
            style={{position: "absolute", top: "2.8em", left: 20, width: "5.5rem"}}
          />
          <div className="fcCol w100 gap0_5">
            <div className="row w100">
              <Select options={bankOptions} label={t("Bank")} name="bankId" autolabel />
              <TextInput label={t("Company")} name="companyName" autolabel />
              <TextInput name="clientNumber" label={t("Client №")} autolabel />
              <MoneyInput name={"sum"} label={t("Sum")} />
              <Select options={currencyOptions} label={t("Currency")} name="currencyId" autolabel />
              <DateInput label={t("From")} name="startDate" />
            </div>
            <div className="row w100">
              <TextInput name="accountNumber" label={t("Acc. №")} autolabel />
              <TextInput name="prName" label={t("Name (P./R.)")} autolabel />
              <TextInput name="requisites" label={t("EDRPOU / TIN (P./R.)")} autolabel />
              <TextInput
                name="purpose"
                label={t("Purpose")}
                autolabel
                generalClassName="fGrow"
                className="fGrow"
                placeholder={`...${t("payment for")}`}
              />
              <DateInput name="finalDate" label={t("To")} style={{justifyContent: "flex-end", gridArea: "dateEnd"}} />
            </div>
          </div>
        </Filters>
        <Divider />
        <ReactTable
          columns={bankStatementsListColumns}
          defaultData={bankStatementsListData}
          current={current}
          onSelect={setCurrent}
          //   onRowDoubleClick={onDoubleClick}
          // selectable={false}
          enableColumnResizing={true}
          className="tableTac fGrow scrollX"
          tableClassName="fGrow"
          loading={loading}
        />
      </SessionFiltersContainer>
    </div>
  );
};

export default BankStatementsList;
