import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";

import ReadOnlyFields from "../../../hoc/ReadOnlyFields";
import {TextAreaInput, TextInput} from "../../../Inputs";
import {getCurrentSku} from "../../SkuSlice";
import Prices from "../SkuVedForm/SkuPricesNew";

import Decoration from "./Decoration";
import VolumeWeightCharacteristicsNew from "./vwCharacteristicsNew";

import "../skuVed.scss";

const SkuPreviewForm = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const currentSku = useSelector(getCurrentSku);

  const initialValues = useMemo(() => {
    return {
      article: "",
      countryId: "",
      brandId: "",
      productGroupId: "",
      productTypeId: "",
      materialId: "",
      colorId: "",
      unitId: "",
      palletId: "",
      productSeries: "",
      complectDesc: "",
      objectId: "",

      colorType: "",
      materialType: "",
      skuFinishing: "",
      skuColor: "",

      settlementPrice: "",
      minimumPrice: "",
      costPrice: "",
      discountPrice: "",
      listPrice: "",
      retailPrice: "",
      salePrice: "",

      name: "",
      nameEng: "",
      skuUnkit: "",
      sku: "",

      batch: "",
      volume: "",
      volumeWeight: "",
      weight: "",
      width: "",
      height: "",
      length: "",
      area: "",
      unitName: "",

      priceChecker: "",
      priceCheckDate: "",
      dimensionCheckDate: "",
      dimensionChecker: "",

      // dimensionCoef: '',
      // areaCoef:'',
      // volumeCoef:'',
      // volumeWeightCoef:'',

      overallSizeCheck: false,
      volumeCheck: false,
      areaCheck: false,
      weightCheck: false,
      palletCheck: false,
    };
  }, []);

  return (
    <Formik
      initialValues={
        currentSku instanceof Object && currentSku
          ? {
              ...currentSku,
              sku: `${currentSku?.brand?.prefix}-${currentSku?.article}`,
              skuFinishing: currentSku?.material?.name,
              skuColor: currentSku?.color?.name,
              skuType: currentSku?.productType?.name,
              unitName: language === "en" ? currentSku?.measurementUnit?.engName : currentSku?.measurementUnit?.name,
            }
          : initialValues
      }
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <Form className="sku_preview_form fcCol w100 aistr gap">
        <ReadOnlyFields>
          <div className="fc jcsb autolabel">
            <div className="fc jcsb w100">
              <TextInput label="UnKit#" name="skuUnkit" type="text" readOnly={true} />
              <TextInput label={t("UnKit type")} id="unKitType" name="unKitType" readOnly={true} />
              <TextInput label={t("SKU")} id="sku" name="sku" readOnly={true} />
              <TextInput label={t("Series")} name="productSeries" />
              <TextInput label={t("Batch")} name="batch" />
            </div>
          </div>
          <div className="fc jcsb autolabel sku_preview_texterea gap2x">
            <div className="row fGrow">
              <span className="pt5">{t("Nomenclature")}</span>
              <TextAreaInput id="name" name="name" width="30%" readOnly height="3rem" className="fGrow" />
            </div>
            <div className="row fGrow">
              <span className="pt5">NameSKU(eng)</span>
              <TextAreaInput id="nameEng" name="nameEng" width="30%" readOnly height="3rem" className="fGrow" />
            </div>
            <div className="row fGrow">
              <span className="pt5">{t("Set description")}</span>
              <TextAreaInput
                id="description"
                name="complectDesc"
                width="30%"
                readOnly
                height="3rem"
                className="fGrow"
              />
            </div>
          </div>
        </ReadOnlyFields>

        <div className="fc nowrap w100 gap aistr">
          <Decoration />

          <div className="priceCharacter__container fcCol fGrow" style={{flexBasis: "25%"}}>
            <Prices mayEdit={false} hasControlCheck />
          </div>
          <div className="vwCharacter__container h100">
            <VolumeWeightCharacteristicsNew />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default SkuPreviewForm;
