export const DISCOUNT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: "auto",
  },
  {
    header: "SKU discount",
    accessorKey: "skuDiscount",
    // size: 100,
  },
  {
    header: "Discount",
    accessorKey: "discount",
    // size: 100,
  },
  {
    header: "Discount sum",
    accessorKey: "discountSum",
    // size: 100,
  },
  {
    header: "%% discount",
    accessorKey: "discountProc",
    // size: 100,
  },
]);

export const SERVICE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Service",
    accessorKey: "service",
    // size: "auto",
  },
  {
    header: "Sum",
    accessorKey: "sum",
    // size: 100,
  },
  {
    header: "Discount",
    accessorKey: "discount",
    // size: 100,
  },
  {
    header: "Discount sum",
    accessorKey: "discountSum",
    // size: 100,
  },
  {
    header: "%% discount",
    accessorKey: "discountProc",
    // size: 100,
  },
]);

export const MAIN_SERVICE_AND_DISCOUNT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Discount application articles",
    accessorKey: "discApplArticles",
    size: 500,
  },
  {
    header: "Spec. disc. amount",
    accessorKey: "specDisAmount",
  },
  {
    header: "Amount with spec. disc.",
    accessorKey: "amountWithSpecDisc",
    // size: 100,
  },
  {
    header: "Indiv. disc. amount",
    accessorKey: "indivDiscAmount",
    // size: 100,
  },
  {
    header: "Amount with indiv. disc.",
    accessorKey: "amountWithIndivDisc",
    // size: 100,
  },
  {
    header: "Payment amount",
    accessorKey: "paymentAmount",
    // size: 100,
  },
]);
