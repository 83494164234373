import {useMemo} from "react";
import {useSelector} from "react-redux";
import {t} from "i18next";

import {getMeasurementUnits} from "../../../../selectors/skuSelectors";
import {TextInput} from "../../../Inputs";
import ControlCheck from "../../../Inputs/controlCheck/ControlCheck";
import useVWCharInputsService from "../../viewWidthCharInputs/vwCharInputsService";

const VolumeWeightCharacteristicsNew = () => {
  const units = useSelector(getMeasurementUnits);
  const measurementUnitsList = useMemo(
    () =>
      units.map(item => {
        return {value: item.name, unitId: item.unitId};
      }),
    [units],
  );

  const {dimensionVertical, mass} = useVWCharInputsService("5em", "Dimension");

  return (
    <div className="fcCol h100 aist">
      <div className="fc jcsb">
        <label htmlFor="" className="header">
          {t("VWC header")}
        </label>
        <TextInput
          label={t("Measurement units")}
          name="unitName"
          width="25"
          // options={measurementUnitsList}
          readOnly={true}
        />
      </div>
      <TextInput
        name="skuType"
        label={t("SKU type")}
        autolabel
        // width="15rem"
        generalClassName="asstr"
        className="fGrow"
      />
      <div className="fcCol gap jcc fGrow">
        {dimensionVertical}
        {mass}
      </div>
      <ControlCheck name="dimensionChecker" date="dimensionCheckDate" initialChecked={false} readOnly={true} />
    </div>
  );
};

export default VolumeWeightCharacteristicsNew;
