import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchClients, getClients} from "../../../slices/PersonSlice";
import {Select} from "../SelectField";

const ClientsSelect = ({name = "clientNumber", label, ...props}) => {
  const {t} = useTranslation();
  const clients = useSelector(getClients);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clients?.length < 1) {
      dispatch(fetchClients());
    }
  }, []);

  const clientsCatalog = useMemo(
    () =>
      clients.map(client => ({
        title: client.clientNumber,
        value: client.clientId,
      })) || [],
    [clients],
  );
  return <Select autolabel label={label || t("Client №") + ":"} name={name} options={clientsCatalog} {...props} />;
};

export default ClientsSelect;
