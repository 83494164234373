import React from "react";
import {useFormikContext} from "formik";

import ToolTip from "../../../../../../utilities/ToolTip";
import {TextInput} from "../../../../../Inputs";

const AccountNumber2 = ({disabled}) => {
  const {setValues, errors, touched} = useFormikContext();

  const accInputStyle = {border: "1px solid #A3B9D9"};
  const accInputStyleAccent = {backgroundColor: "#fff", border: "1px solid #A3B9D9"};
  function formErrorInputStyle(fieldName) {
    return errors[fieldName] && touched[fieldName] ? {border: "1px solid red"} : {};
  }
  function limitInput({inputValue, n, name}) {
    if (inputValue.length > n) {
      setValues(prev => ({...prev, [name]: inputValue.slice(0, n)}));
      return;
    }
  }

  return (
    <div className="row gap0_5">
      <div className="row gap1">
        <ToolTip title="account country">
          <TextInput
            name="accCountry"
            width="3em"
            style={accInputStyle}
            readOnly
            placeholder="UAH"
            inputClassName="leftPlaceholder"
          />
        </ToolTip>
        <ToolTip title="account country code">
          <TextInput
            name="accCountryCode"
            width="2.5em"
            style={accInputStyle}
            readOnly
            placeholder="kk"
            inputClassName="leftPlaceholder"
          />
        </ToolTip>
      </div>

      <ToolTip title="persona id ">
        <TextInput
          name="personaId"
          width="5.5em"
          style={accInputStyle}
          readOnly
          placeholder="PersonaID"
          inputClassName="leftPlaceholder"
        />
      </ToolTip>

      <div className="row gap1">
        <ToolTip title="balance sheet account number">
          <TextInput
            name="balanceSheetAccId"
            width="3.4em"
            style={disabled ? accInputStyle : {...accInputStyleAccent, ...formErrorInputStyle("balanceSheetAccId")}}
            //   handleInputChange={onBalanceSheetAccIdChange}
            //   onBlur={handleBlur}
            // type="number"
            inputClassName="inputTypeNumberNoArrows"
            disabled={disabled}
          />
        </ToolTip>
        <ToolTip title="+i balance sheet account">
          <TextInput name="balanceSheetAccIdRel" width="4.5em" style={accInputStyle} readOnly />
        </ToolTip>
        <ToolTip title="account creation current year">
          <TextInput name="currentYear" width="2.5em" style={accInputStyle} readOnly />
        </ToolTip>
        <ToolTip title="reserve code">
          <TextInput
            // type="number"
            inputClassName="inputTypeNumberNoArrows"
            name="reserveCode"
            width="2.5em"
            style={
              disabled
                ? accInputStyle
                : {
                    ...accInputStyleAccent,
                    ...formErrorInputStyle("reserveCode", disabled ? accInputStyle : {}),
                  }
            }
            disabled={disabled}
            // onBlur={handleBlur}
            handleInputChange={e => limitInput({inputValue: e.target.value, n: 2, name: "reserveCode"})}
          />
        </ToolTip>
      </div>
      <ToolTip title="currency code">
        <TextInput
          // type="number"
          inputClassName="inputTypeNumberNoArrows"
          name="currencyId"
          width="3em"
          style={disabled ? accInputStyle : {...accInputStyleAccent, ...formErrorInputStyle("currencyId")}}
          disabled={disabled}
          //   onBlur={handleBlur}
          handleInputChange={e => limitInput({inputValue: e.target.value, n: 3, name: "currencyId"})}
        />
      </ToolTip>
    </div>
  );
};
const AccountNumber = ({accountName}) => {
  const {values} = useFormikContext();

  return (
    <div className="row gap0_5">
      <div className="row gap1">
        <ToolTip title="account country">
          <AccountInput value={values?.[accountName]?.slice(0, 3) ?? ""} width="3.5em" />
        </ToolTip>
        <ToolTip title="account country code">
          <AccountInput value={values?.[accountName]?.slice(3, 5) ?? ""} width="2.5em" />
        </ToolTip>
      </div>

      <ToolTip title="persona id ">
        <AccountInput value={values?.[accountName]?.slice(5, 11) ?? ""} width="5.5em" />
      </ToolTip>

      <div className="row gap1">
        <ToolTip title="balance sheet account number">
          <AccountInput value={values?.[accountName]?.slice(11, 15) ?? ""} width="3.4em" />
        </ToolTip>
        <ToolTip title="+i balance sheet account">
          <AccountInput value={values?.[accountName]?.slice(-13, -7) ?? ""} width="4.5em" />
        </ToolTip>
        <ToolTip title="account creation current year">
          <AccountInput value={values?.[accountName]?.slice(-7, -5) ?? ""} width="2.5em" />
        </ToolTip>
        <ToolTip title="reserve code">
          <AccountInput value={values?.[accountName]?.slice(-5, -3) ?? ""} width="2.5em" />
        </ToolTip>
      </div>
      <ToolTip title="currency code">
        <AccountInput value={values?.[accountName]?.slice(-3) ?? ""} width="3em" />
      </ToolTip>
    </div>
  );
};
const AccountInput = ({value, width}) => {
  return (
    <input
      className="inputTypeNumberNoArrows"
      readOnly
      style={{border: "1px solid #A3B9D9", width: width}}
      value={value}
    />
  );
};

export default AccountNumber;
