import {useEffect} from "react";
import {useField, useFormikContext} from "formik";
import {t} from "i18next";
import PropTypes from "prop-types";

import {useDebouncedCallback} from "../../hook/debounced";
import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";

const TextInput = ({
  label,
  width = "100%",
  className = "",
  generalClassName = "",
  inputClassName = "",
  questionItemClassName = "",
  question = null,
  func = null,
  autolabel,
  labelWrap,
  labelStyles,
  handleInputChange = () => {},
  onBlur = () => {},
  disabled = false,
  placeholder = "",
  hasTitle = false,
  setref,
  style,
  required = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const {tooltipShown, onMouseEnter, onMouseLeave, setTooltipShown} = useInputsTooltip(field);

  const {handleChange, submitCount, values} = useFormikContext();

  const funcDebounce = useDebouncedCallback(value => {
    if (func !== null) func(value);
  }, 1000);

  const onChange = e => {
    handleChange(e);
    handleInputChange(e);
    if (e.target.value.length > 0) {
      setTooltipShown(false);
    }
    if (func !== null) {
      funcDebounce(e.target.value);
    }
  };
  // useEffect(() => {
  //   if (func !== null && (!field?.value || field?.value === "")) {
  //     funcDebounce(null);
  //   }
  // }, [field?.value]);

  const labelText = label
    ? label?.[label?.length - 1] === ":"
      ? required
        ? t(label) + "*"
        : t(label)
      : (required ? t(label) + "*" : t(label)) + ":"
    : "";
  return (
    <div className={`project_input ${generalClassName} ${labelWrap ? "" : "labelWrap"}`} style={style}>
      {label && (
        <label
          htmlFor={props.name}
          style={{...labelStyles, whiteSpace: "pre"}}
          className={autolabel ? "autolabel" : ""}
        >
          {/* { typeof label === "string" ? t(label) : label }  */}
          {labelText}
        </label>
      )}
      <div className="project_input_container" style={{width: width, position: "relative"}}>
        <div className={className}>
          <input
            {...props}
            {...field}
            className={inputClassName}
            disabled={disabled}
            autoComplete="off"
            placeholder={typeof placeholder === "string" ? t(placeholder) : placeholder}
            value={field.value || ""}
            onChange={onChange}
            onBlur={e => onBlur(e, field.value)}
            onMouseEnter={question ? onMouseEnter : null}
            onMouseLeave={question ? onMouseLeave : null}
            title={hasTitle && field.value ? field.value : null}
            ref={setref}
            style={props.readOnly || disabled ? {cursor: "default"} : {background: "white"}}
          />
        </div>
        {meta.touched && meta.error && submitCount > 0 ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? (
        <QuestionItem title={question} tooltipShown={tooltipShown} className={questionItemClassName} />
      ) : null}
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  className: PropTypes.string,
  question: PropTypes.string,
  func: PropTypes.func,
  autolabel: PropTypes.bool,
  labelStyles: PropTypes.object,
  handleInputChange: PropTypes.func,
  generalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hasTitle: PropTypes.bool,
  props: PropTypes.array,
};

export default TextInput;
