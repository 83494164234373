import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

const BankClientButtonRow = ({
  currentBankTransactionId,
  onAccountAssociationBtnClick,
  onToTheClientBalanceBtnClick,
  onConversionBtnClick,
  onCheckTransactionsBtnClick,
  onConductBtnClick,
  onCreateDebtBtnClick,
  hasCurrentTranscationAssociation,
}) => {
  return (
    <div className="row w100">
      <FunctionButton
        name="Account association"
        onClick={onAccountAssociationBtnClick}
        autoWidth
        disabled={!currentBankTransactionId || hasCurrentTranscationAssociation}
      />
      <FunctionButton
        name="To the Client`s balance"
        onClick={onToTheClientBalanceBtnClick}
        autoWidth
        disabled={!currentBankTransactionId || !hasCurrentTranscationAssociation}
      />
      <FunctionButton name="Conversion" onClick={onConversionBtnClick} autoWidth className="noFunctional" />
      <FunctionButton
        name="Check transactions"
        onClick={onCheckTransactionsBtnClick}
        autoWidth
        className="noFunctional"
      />
      <FunctionButton name="Conduct" onClick={onConductBtnClick} autoWidth className="noFunctional" />
      <FunctionButton name="Create debt" onClick={onCreateDebtBtnClick} autoWidth className="noFunctional" />
    </div>
  );
};

export default BankClientButtonRow;
