import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {VERIF_SELECT_COLUMNS} from "../../ReceiptAndVerificationPf/constants";

const TableVerification = () => {
  const verifTableColoumns = useColumns(VERIF_SELECT_COLUMNS);

  return (
    <div style={{height: "100%"}}>
      <ReactTable
        defaultData={[]}
        columns={verifTableColoumns}
        style={{overflowX: "scroll"}}
        className=""
        enableMultiSelection={true}
      />
    </div>
  );
};

export default TableVerification;
