import React from "react";
import {useTranslation} from "react-i18next";

import {TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";
import useSkuCatalogsService from "../../../../SKU/SkuCatalogsService";

const MistakeFilters = () => {
  const {t} = useTranslation();
  const {brandList, colorsList, materialsList, productGroups, productTypes} = useSkuCatalogsService();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "1em",
          paddingBottom: "1em",
        }}
        className="autolabel sku-modal-form__mainChar"
      >
        <TextInput label="SKU:*" name="article" type="text" width={"10em"} readOnly />
        <Select label="Brand" name="brandId" options={brandList} multi={true} width={"11em"} readOnly required />
        <Select label="Group" name="productGroupId" options={productGroups} multi={true} width={"12em"} required />
        <Select label="Type" name="productTypeId" options={productTypes} width="12em" multi={true} required />
        <Select label={t("Finishing*")} name="materialId" options={materialsList} width="10em" multi={true} readOnly />
        <Select label={t("Color*")} name="colorId" options={colorsList} width="10em" multi={true} readOnly />
      </div>

      <div className="fc jcfe">
        <TextInput label={t("Series")} name="productSeries" readOnly />
        <TextInput label={t("Batch")} name="batch" readOnly />
      </div>
    </>
  );
};

export default MistakeFilters;
