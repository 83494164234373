export const ACC_ASSOTIATION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    size: 20,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 70,
    isSortable: true,
  },
  {
    header: "Bank",
    accessorKey: "bank",
    size: 70,
    isSortable: true,
  },
  {
    header: "MFI",
    accessorKey: "mfi",
    size: 70,
  },
  {
    header: "External account №",
    accessorKey: "externalAccNumber",
    // size: 100,
    // isSortable: true,
  },
  {
    header: "Account naming",
    accessorKey: "externalAccNaming",
    size: 260,
  },
  {
    header: "Internal account №",
    accessorKey: "internalAccNumber",
    // size: 100,
  },
  {
    header: "Account Type",
    accessorKey: "accountType",
    size: 160,
  },
  {
    header: "Account naming",
    accessorKey: "internalAccNaming",
    size: 360,
  },
  {
    header: "Payer account",
    accessorKey: "payerAcc",
    // size: 100,
  },
  {
    header: "Counterparty Type",
    accessorKey: "counterpartyType",
    size: 100,
  },
  {
    header: "Client №",
    accessorKey: "clientNumber",
    size: 120,
  },
]);
