import Divider from "../../../../../utilities/Divider/Divider";
import {SessionFiltersContainer} from "../../../../conteiners/filters/Filter.hoc";

import AccAssociationAccounts from "./parts/AccAssociationAccounts";
import AccAssociationFilters from "./parts/AccAssociationFilters";
import AccAssociationTable from "./parts/AccAssociationTable";

const AccAssociationTab = () => {
  return (
    <div className="fcCol h100 aistr pb0_5">
      <SessionFiltersContainer>
        <AccAssociationFilters />
        <Divider />
        <AccAssociationAccounts />
        <AccAssociationTable />
      </SessionFiltersContainer>
    </div>
  );
};

export default AccAssociationTab;
