import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

export const ListSelect = ({
  label,
  width = "100%",
  // styles = null,
  id,
  options,
  makeEffect, /// функция (не обязательная) которая должна сработать когда происходит выбор из выпадающего списка
  question, ///// пояснение к полю
  myRef,
  autolabel,
  labelStyles,
  style,
  type = "onlyNameSelect",
  ...props
}) => {
  const [viewSelect, setViewSelect] = useState(false);

  const [field, meta, helpers] = useField(props);
  const {setFieldValue} = useFormikContext(field);

  const setOpen = () => {
    if (options.length !== 0) {
      setViewSelect(true);
    }
  };

  const close = useCallback(() => {
    setViewSelect(false);
  }, [options]);

  const generateSelect = useCallback(
    options => {
      if (options)
        return options.map((option, i) => {
          return (
            <li
              className="select_option"
              key={i}
              onClick={() => {
                helpers.setValue(option);
                close();
              }}
            >
              {option.length > 32 ? option.slice(0, 32) + "..." : option}
            </li>
          );
        });
    },
    [options, makeEffect],
  );

  const optionsLi = generateSelect(options);

  const {i18n} = useTranslation();

  return (
    <div className="project_input select" id={id} style={style}>
      {label && (
        <label htmlFor={props.name} style={autolabel ? {width: "auto"} : labelStyles}>
          {/* { typeof label === "string" ? t(label) : label }  */}
          {label}
        </label>
      )}
      <div
        style={{display: "flex", position: "relative", width: width, maxHeight: "100px"}}
        onMouseLeave={close}
        ref={
          myRef
            ? el => {
                myRef.refElement.current[myRef.i] = el;
              }
            : null
        }
      >
        <input
          {...field}
          {...props}
          autoComplete="off"
          style={{width: "100%", ...(props.readOnly || props.disabled ? {cursor: "default"} : {background: "white"})}}
          onClick={() => {
            setOpen();
            if (myRef) {
              const {refFunc, i} = myRef;
              refFunc(i);
            }
          }}
          readOnly={true}
        />
        {meta.error && meta.touched ? <div className="error">{meta.error}</div> : null}

        <div
          className="select__list_button"
          onClick={() => {
            setOpen();
          }}
        >
          ▼
        </div>

        {viewSelect && optionsLi.length !== 0 ? (
          <div className="select__list disable">
            <ul>{optionsLi}</ul>
          </div>
        ) : null}
        {question ? <QuestionItem title={question} /> : null}
      </div>
    </div>
  );
};

ListSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  id: PropTypes.string,
  selectionMass: PropTypes.array,
  makeEffect: PropTypes.func,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  myRef: PropTypes.object,
  props: PropTypes.array,
};
