import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../../utilities/dateFormat";
import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectBankTransaction} from "../../../FinBlockSlice";
import {
  addNewStatementDirective,
  chooseDirectiveForStatement,
  selectAllCurrency,
  selectAllDirectives,
} from "../../../FinSettings/FinSettingsSlice";

import {CLIENT_ORDERS_TABLE_COLUMNS} from "./constants";

const ClientOrderModal = ({setModalActive}) => {
  const dispatch = useDispatch();

  const directives = useSelector(selectAllDirectives);
  const allCurrency = useSelector(selectAllCurrency);
  const bankTransaction = useSelector(selectBankTransaction);
  const clientId = bankTransaction?.counterpartyAccount?.owner?.clientId;
  const transactionId = bankTransaction?.transactionId;

  const [currentDirectiveId, setCurrentDirectiveId] = useState(null);

  const loading = false;
  const clientOrdersTableColumns = useColumns(CLIENT_ORDERS_TABLE_COLUMNS);
  const clientOrdersTableData =
    directives?.map(({directiveId, directiveNumber, sumPlan, sumFact, note, createdAt, currencyId}, index) => ({
      id: directiveId,
      number: ++index,
      date: dateFormat(createdAt),
      directiveNumber,
      sumPlan,
      sumFact,
      currency: allCurrency?.find(currency => currencyId === currency.currencyId)?.shortName ?? "",
      note,
    })) ?? [];

  const onOpenBtnClick = () => {
    if (!currentDirectiveId || !bankTransaction) return;

    dispatch(chooseDirectiveForStatement({directiveId: currentDirectiveId, transactionId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setModalActive(false);
      }
    });
  };

  const onCreateNewOrderBtnClick = () => {
    if (!clientId || !bankTransaction) return;

    dispatch(addNewStatementDirective({clientId, transactionId: bankTransaction?.transactionId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setModalActive(false);
      }
    });
  };

  const onCancelBtnClick = () => {
    setModalActive(false);
  };

  const onClientOrdersTableRowSelect = id => {
    setCurrentDirectiveId(id);
  };

  return (
    <div className="fcCol jcsb fGrow padding gap2x">
      <div className="bold tac" style={{fontSize: "20px", width: "40%", lineHeight: "25px"}}>
        {t("orderHasAlreadyBeenCreated")}
      </div>
      <ReactTable
        columns={clientOrdersTableColumns}
        defaultData={clientOrdersTableData}
        current={currentDirectiveId}
        onSelect={onClientOrdersTableRowSelect}
        //   onRowDoubleClick={onDoubleClick}
        className="tableTac fGrow scrollX"
        tableClassName="fGrow"
        loading={loading}
      />
      <div className="row w100 jcsa">
        <FunctionButton name="Yes, open!" autoWidth onClick={onOpenBtnClick} disabled={!currentDirectiveId} />
        <FunctionButton name="Create new " autoWidth onClick={onCreateNewOrderBtnClick} />
        <FunctionButton name="Cancel" autoWidth onClick={onCancelBtnClick} />
      </div>
    </div>
  );
};

export default ClientOrderModal;
