import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {MoneyInput, NumberInput, TextAreaInput} from "../../../../../Inputs";

import CounterPartyLine from "./CounterPartyLine";

const MiniOverDraftParams = ({
  onPauseMiniOverdraftBtnClick,
  onRestoreMiniOverdraftBtnClick,
  onDeleteMiniOverdraftBtnClick,
  onSaveMiniOverdraftBtnClick,
}) => {
  const {t} = useTranslation();

  return (
    <div className="fcCol gap aistr">
      <div className="header asfs">{`1. ${t("Mini-overdraft params")}`}</div>
      <div className="italic">{t("miniOverdraftParamsPartText")}</div>
      <CounterPartyLine namePart="MiniOverDraft" />
      <div className="row gap aibl">
        {t("debtAmount")}
        <div className="row gap aibl">
          <MoneyInput name="debtMoney" label="to" autolabel />
          {t("and")}
          <NumberInput name="debtPersentage" label={t("to %")} autolabel width="5rem" />
        </div>
      </div>
      <TextAreaInput name="miniDraftParamsNote" placeholder={t("notes")} />
      <div className="row">
        <div className="row gap">
          <FunctionButton name="Pause" autoWidth disabled onClick={onPauseMiniOverdraftBtnClick} />
          <FunctionButton name="Restore" autoWidth disabled onClick={onRestoreMiniOverdraftBtnClick} />
        </div>
        <div className="row gap">
          <FunctionButton name="Delete" autoWidth disabled onClick={onDeleteMiniOverdraftBtnClick} />
          <FunctionButton name="Save" autoWidth disabled onClick={onSaveMiniOverdraftBtnClick} />
        </div>
      </div>
    </div>
  );
};

export default MiniOverDraftParams;
