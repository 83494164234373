import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat, timeFormat} from "../../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../../utilities/formatAccountNumber";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchAllBankClientStatements, selectBankClientStatements, selectFinBlockLoading} from "../../../FinBlockSlice";
import {BANK_STATEMENTS_LIST_TABLE_COLUMNS} from "../../constants";

const useBankStatementsList = () => {
  const bankStatementsListColumns = useColumns(BANK_STATEMENTS_LIST_TABLE_COLUMNS);
  const dispatch = useDispatch();

  const bankClientStatements = useSelector(selectBankClientStatements);

  const bankStatementsListData = useMemo(
    () =>
      bankClientStatements?.map((statement, index) => {
        const isDebit = Boolean(statement?.isDebit);
        return {
          id: statement?.statementId,
          trId: statement?.transactionUuid,
          number: index + 1,
          bank: statement?.bank,
          mfi: statement?.bankMfis,
          accountNumber: formatAccountNumber(statement?.iban),
          accHolderName: "?",

          docNumber: statement?.documentNumber,
          docDate: "?",
          date: dateFormat(statement?.dateAndTime),
          time: timeFormat(statement?.dateAndTime),
          costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
          revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
          currency: "?",
          counterpartyAccountNumber: formatAccountNumber(statement?.counterpartyIban),
          requisitesC: statement?.counterpartyEdrpou,
          counterpartyName: statement?.counterparty,
          counterpartyBank: statement?.counterpartyBank,
          mfiC: statement?.counterpartyBankMfis,
          paymentPurpose: statement?.paymentPurpose,
          rate: statement?.rate,
          equivalent: statement?.nbuEquivalentSum,
          commissionsAmmount: statement?.feesSum,
          remainder: isDebit ? statement?.debitAccount?.balance : statement?.creditAccount?.balance, // !!!!!
          reference: "?",
          operationCode: statement?.operationCode,
          status: "?",
        };
      }) || [],
    [bankClientStatements],
  );
  const onBankStatementsListFilterChange = () => {};
  const loading = useSelector(selectFinBlockLoading);
  const bankOptions = [];
  const currencyOptions = [];

  useEffect(() => {
    dispatch(fetchAllBankClientStatements());
  }, []);

  return {
    loading,
    bankOptions,
    currencyOptions,
    bankStatementsListColumns,
    bankStatementsListData,
    onBankStatementsListFilterChange,
  };
};

export default useBankStatementsList;
