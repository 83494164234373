import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {debounce} from "lodash";

import {TextAreaInput, TextInput} from "../../../Inputs";
import ImgBox from "../../../Inputs/fileSelect/ImgBox/ImgBox";
import {getColorImages, getDecorImages, getDrawings, getSkuImages} from "../../SkuSlice";
import {processImages} from "../constants/functions";

const Decoration = () => {
  const {t} = useTranslation();
  const [boxLSize, seBoxLSize] = useState({width: "170px", height: "152px"});

  const skuImages = useSelector(getSkuImages),
    colorImages = useSelector(getColorImages),
    decorImages = useSelector(getDecorImages),
    drawings = useSelector(getDrawings);

  useEffect(() => {
    updateSizeParams();
    window.addEventListener("resize", debouncedResize);
    return () => window.removeEventListener("resize", debouncedResize);
  }, []);

  const updateSizeParams = () => {
    const box = document.getElementsByClassName("sku_item_image")[0];
    const styles = window.getComputedStyle(box);
    seBoxLSize({width: styles.width, height: styles.height});
  };
  const debouncedResize = debounce(updateSizeParams, 2000);
  return (
    <div className="fc nowrap " style={{alignItems: "start", width: "55%", height: "100%"}}>
      <div className="fc nowrap jcc aistr" style={{gap: "10px", height: "100%"}}>
        <ImgBox
          label={t("General view")}
          width={boxLSize?.width}
          height={boxLSize?.height}
          classNames="sku_item_image"
          className="fGrow"
          imgs={processImages(skuImages) || []}
        />
        <ImgBox
          label={t("Drawings")}
          width={boxLSize?.width}
          height={boxLSize?.height}
          classNames="sku_item_image"
          className="fGrow"
          imgs={processImages(drawings) || []}
        />
      </div>
      <div
        className="fc wrap"
        style={{marginLeft: "1em", height: "100%", width: "60%", justifyContent: "space-between"}}
      >
        <div className="fc nowrap aist gap5" style={{height: "49%", gap: "5px", flexGrow: "2"}}>
          <div className="fcCol aistr h100" style={{width: "70%"}}>
            <TextInput label={t("Finishing")} name="skuFinishing" autolabel />
            <ImgBox
              showIndicators={false}
              classNames="smaller-image-box"
              imgs={processImages(decorImages) || []}
              className="fc jcc fGrow"
            />
          </div>
          <TextAreaInput label={t("Finishing type")} name="materialType" width="100%" height="100%" readOnly={true} />
        </div>
        <div className="fc nowrap aist gap5" style={{height: "49%", gap: "5px", flexGrow: "2"}}>
          <div className="fcCol aistr h100" style={{width: "70%"}}>
            <TextInput label={t("Color")} name="skuColor" autolabel />
            <ImgBox
              showIndicators={false}
              classNames="smaller-image-box"
              imgs={processImages(colorImages) || []}
              className="fc jcc fGrow"
            />
          </div>
          <TextAreaInput label={t("Color type")} name="colorType" width="100%" height="100%" readOnly={true} />
        </div>
      </div>
    </div>
  );
};

export default Decoration;
