import {useEffect} from "react";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import ModalForm from "../../../../Modal/FormModal";
import ClientOrderModal from "../ClientOrderModal/ClientOrderModal";

import AssociatedTransaction from "./parts/AssociatedTransaction";
import CounterpartyAssociation from "./parts/CounterpartyAssociation";
import DirectiveAndOrder from "./parts/DirectiveAndOrder";
import RealTransaction from "./parts/RealTransaction";
import useAccAssotiation from "./useAccAssotiation";

const AccountAssociationAndTransaction = ({hasAssociation}) => {
  const {
    isModalActive,
    currentOrderId,
    onCreateDirectiveBtnClick,
    onSaveAssociationBtnClick,
    onAssociatiedTransactionSelect,
    associatedTransactionTableData,
    realTransactionTableData,
    onRealTransactionSelect,
    bankTransaction,
    statementDirective,
    setModalActive,
  } = useAccAssotiation();

  const {values, setValues} = useFormikContext();

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      counterpartyType: bankTransaction?.internalTransaction ? "client" : "",
      clientId: bankTransaction?.internalTransaction ? bankTransaction?.counterpartyAccount?.owner?.clientId : "",
      transactionType: bankTransaction?.internalTransaction ? "clientBalance" : "",
      clientNumber: bankTransaction?.internalTransaction
        ? bankTransaction?.counterpartyAccount?.owner?.clientNumber
        : "",
    }));
  }, [bankTransaction?.transactionId, bankTransaction?.internalTransaction?.transactionId]);

  return (
    <div className="fcCol w100 pt gap0_5">
      <RealTransaction
        realTransactionTableData={realTransactionTableData}
        onRealTransactionSelect={onRealTransactionSelect}
      />
      <AssociatedTransaction
        associatedTransactionTableData={associatedTransactionTableData}
        onAssociatiedTransactionSelect={onAssociatiedTransactionSelect}
      />
      {!hasAssociation && (
        <CounterpartyAssociation
          onSaveAssociationBtnClick={() => onSaveAssociationBtnClick(values)}
          hasAssociation={!!bankTransaction?.internalTransaction}
        />
      )}
      {hasAssociation && (
        <DirectiveAndOrder
          orderId={currentOrderId}
          onCreateDirectiveBtnClick={onCreateDirectiveBtnClick}
          noCounterpartyAssociation={hasAssociation}
          hasAssociation={!!bankTransaction?.internalTransaction}
          isDirectiveCreated={!!statementDirective}
        />
      )}

      <ModalForm
        label={t("Client Order")}
        active={isModalActive}
        setModalActive={setModalActive}
        closable
        Component={ClientOrderModal}
        style={{width: "80rem"}}
      />
    </div>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    paymentCurrency: 980,
  }),
})(AccountAssociationAndTransaction);
