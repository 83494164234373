import AssociatedTransaction from "./parts/AssociatedTransaction";
import RealTransaction from "./parts/RealTransaction";
import useAccAssotiation from "./useAccAssotiation";

const ViewAccountAssociationAndTransaction = () => {
  const {
    onAssociatiedTransactionSelect,
    associatedTransactionTableData,
    realTransactionTableData,
    onRealTransactionSelect,
  } = useAccAssotiation();

  return (
    <div className="fcCol w100  gap0_5">
      <RealTransaction
        realTransactionTableData={realTransactionTableData}
        onRealTransactionSelect={onRealTransactionSelect}
      />
      <AssociatedTransaction
        associatedTransactionTableData={associatedTransactionTableData}
        onAssociatiedTransactionSelect={onAssociatiedTransactionSelect}
      />
    </div>
  );
};

export default ViewAccountAssociationAndTransaction;
