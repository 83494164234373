import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {addNewPerson} from "../../../slices/PersonSlice";
import QuestionItem from "../../../utilities/QuestionItem";
import {TextAreaInput, TextInput} from "../../Inputs";
import PhoneInput from "../../Inputs/PhoneInput";

const AddPersonModal = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const {name} = useParams();
  const personList = useSelector(store => store.person.persons);

  const [showCheckBox, setShowCheckBox] = useState(false);
  const [confident, setConfident] = useState(false);
  const [initialName, setInitialName] = useState(null);

  useEffect(() => {
    if (name) {
      var nameparts = name.split("&");
      setInitialName({
        firstName: nameparts[1],
        lastName: nameparts[0],
        patronym: nameparts[2],
        confirm: false,
      });
    }
  }, [name]);

  const initialValues = useMemo(() => {
    return {
      firstName: "",
      lastName: "",
      patronym: "",
      confirm: false,
      note: "",
    };
  }, [confident]);

  // const checkExaсtMatch = (persona) =>{
  //     const fullname = [persona.firstName, persona.patronym, persona.lastName].join(" ");
  //     var answ =  personList.find(persona => persona.name === fullname)
  //     console.log(!!answ)
  //     if(!!answ){setShowCheckBox(!!answ);}
  //     return !!answ
  // }
  const unconfidentValidation = {
    firstName: Yup.string()
      .required("Введіть iм'я")
      .test("", "персона з таким ім'ям вже існує", function (item) {
        // console.log("item", item);
        if (!item || item === "") {
          return false;
        }
        // console.log("validate else");
        if (!this.parent.firstName || this.parent.firstName === "") {
          const fullname =
            this.parent.patronym && this.parent.patronym !== ""
              ? [item.trim(), this.parent.patronym.trim()].join(" ")
              : item.trim();
          console.log("fullname", fullname);
          var answ = personList.find(persona => persona.fullName === fullname);
          setShowCheckBox(!!answ);
        }
        return this.parent.confirm === !!answ;
      }),
    patronym: Yup.string(),
    lastName: Yup.string()
      // .required("Введіть призвіще")
      .test("", "вже існує", function (item) {
        // console.log("item", item);
        if (!item || item === "") {
          return true;
        }
        // console.log("validate else");
        const fullname =
          this.parent.patronym && this.parent.patronym !== ""
            ? [this.parent.firstName.trim(), this.parent.patronym.trim(), item.trim()].join(" ")
            : [this.parent.firstName.trim(), item.trim()].join(" ");
        // console.log("fullname", fullname);
        var answ = personList.find(persona => persona.fullName === fullname);
        setShowCheckBox(!!answ);
        return this.parent.confirm === !!answ;
      }),
    confirm: Yup.boolean().test("", [t("Confirm")], function (item) {
      return showCheckBox === item;
    }),
  };

  const onConfidensChecked = resetForm => {
    if (confident) {
      setConfident(false);
      return;
    }
    Swal.fire({
      title: t("Sure"),
      text: t("Inputs will be removed"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        setConfident(true);
        setShowCheckBox(false);
        setInitialName(null);
        resetForm();
      }
    });
  };

  return (
    // <div className="login-form__container">
    //     <div className="login_form">
    //     <label className='label login_form_label'>Створення персони</label>

    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape(confident ? {} : unconfidentValidation)}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={({firstName, lastName, patronym, note}, {resetForm}) => {
        // const full =
        //   patronym === ""
        //     ? {firstName: firstName.trim(), lastName: lastName.trim()}
        //     : {
        //         firstName: firstName.trim(),
        //         lastName: lastName.trim(),
        //         patronym: patronym.trim(),
        //       };

        const pers = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          patronym: patronym.trim(),
          note,
        };

        var body = confident ? {isConfidential: true} : pers;

        dispatch(addNewPerson(body)).then(() => {
          resetForm();
          setShowCheckBox(false);
        });
      }}
    >
      {({
        // values,
        errors,
        // meta,
        isSubmitting,
        resetForm,
      }) => (
        <Form className="project_form">
          <TextInput label="Name" name="firstName" type="text" readOnly={confident} required />
          <TextInput label={t("Last Name")} name="lastName" type="text" readOnly={confident} />
          <TextInput label={t("Patronymic Name")} name="patronym" type="text" readOnly={confident} />

          <PhoneInput label="Phone" name="phone" readOnly={confident} required />

          <TextAreaInput name="note" label={t("Notes")} width={"100%"} />

          {showCheckBox && (
            <label htmlFor="confirm">
              <Field name="confirm" type="checkbox" />
              {t("Confirm creation")}
              {errors.confirm && <div className="error error__hidden">{errors.confirm}</div>}
            </label>
          )}
          <div className="fc jcfs">
            <label htmlFor="" style={{display: "block"}}>
              <input
                type="checkbox"
                readOnly={true}
                onClick={() => onConfidensChecked(resetForm)}
                checked={confident}
              />
              {t("Confidential")}
            </label>
            <QuestionItem title={[t("No data person")]} />
          </div>
          <button type="submit" disabled={isSubmitting} className="fixed_button fixed_button__blue">
            {t("Add")}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddPersonModal;
