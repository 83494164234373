import {useEffect} from "react";
import {t} from "i18next";

import useContextMenu from "../../../hook/useContextMenu";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import ReactTable from "../../conteiners/ReactTable";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import {DateInput, MoneyInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import BankClientButtonRow from "./parts/BankClientButtonRow";
import BankOperationList from "./tabs/BankOperationList/BankOperationList";
import BankStatementsList from "./tabs/BankStatementsList/BankStatementsList";
import BankClientContextMenu from "./BankClientContextMenu";
import useBankClient from "./useBankClient";

export const BANK_TRANSACTION_STATUSES = Object.freeze({NEW: "new", ASSOCIATED: "associated", CONDUCTED: "conducted"});

export const formTransactionStatus = transaction => {
  if (!transaction) return "";
  const {isFullfiled, isCompleted} = transaction;
  if (!isFullfiled && !isCompleted) return t(BANK_TRANSACTION_STATUSES.NEW);
  if (isFullfiled && !isCompleted) return t(BANK_TRANSACTION_STATUSES.ASSOCIATED);
  if (isCompleted) return t(BANK_TRANSACTION_STATUSES.CONDUCTED);
};

const BankClient = () => {
  const BANK_CLIENT_WINDOW_NAME = "BankClient";

  const {
    bankOptions,
    currencyOptions,
    onBankClientFilterChange,
    onGetDataBtnClick,
    folderPickerRef,
    handleFolderUpload,
    bankClientTableColumns,
    bankClientTableData,
    loading,
    currentBankTransactionId,
    onBankClientTableRowSelect,
    onAccountAssociationBtnClick,
    onToTheClientBalanceBtnClick,
    onConversionBtnClick,
    onCheckTransactionsBtnClick,
    onConductBtnClick,
    onCreateDebtBtnClick,
    hasCurrentTranscationAssociation,
  } = useBankClient();
  const {setTabs} = useTabsContext();
  const {menuVisible, menuPosition, handleContextMenu} = useContextMenu();

  useEffect(() => {
    setTabs(prev => [
      prev[0],
      {TabComponent: BankStatementsList, index: 1, title: "Bank-Client statements (register)"},
      {TabComponent: BankOperationList, index: 2, title: t("remains as of {{number}}", {number: "2699/09"})},
    ]);
  }, []);

  return (
    <div className="fcCol h100 w100 pb" id="context-menu-container">
      <SessionFiltersContainer window={BANK_CLIENT_WINDOW_NAME} onFilterChange={onBankClientFilterChange}>
        <Filters>
          <div className="row aibl" style={{position: "absolute", top: "2.8em", left: 20, width: "50px"}}>
            <TextInput name="trId" placeholder="tr.id" width="5.5rem" />
            <FunctionButton name="Get data" onClick={onGetDataBtnClick} autoWidth />
            <input
              type="file"
              style={{display: "none"}}
              webkitdirectory="true"
              ref={folderPickerRef}
              multiple
              onChange={handleFolderUpload}
            />
          </div>

          <div className="fcCol w100 gap0_5">
            <div className="row w100">
              <Select options={bankOptions} label="Bank" name="bankId" autolabel />
              <TextInput label="Company" name="companyName" autolabel />
              <TextInput name="clientNumber" label="Client №" autolabel />
              <MoneyInput name="sum" label="Sum" />
              <Select options={currencyOptions} label="Currency" name="currencyId" autolabel />
              <DateInput label="From" name="startDate" />
            </div>
            <div className="row w100 pl7x">
              <TextInput name="accountNumber" label="Acc. №" autolabel />
              <TextInput name="prName" label="Name (P./R.)" autolabel />
              <TextInput name="requisites" label="EDRPOU / TIN (P./R.)" autolabel />
              <TextInput
                name="purpose"
                label="Purpose"
                autolabel
                generalClassName="fGrow"
                className="fGrow"
                placeholder={`...${t("payment for")}`}
              />
              <DateInput label="To" name="finalDate" />
            </div>
          </div>
        </Filters>
        <Divider />
        <div className="fcCol gap fGrow w100">
          <ReactTable
            columns={bankClientTableColumns}
            defaultData={bankClientTableData}
            current={currentBankTransactionId}
            onSelect={onBankClientTableRowSelect}
            enableColumnResizing={true}
            className="tableTac fGrow scrollX"
            tableClassName="fGrow"
            loading={loading}
            selectOnRightBtn={true}
            handleContextMenu={handleContextMenu}
          />
          <BankClientButtonRow
            currentBankTransactionId={currentBankTransactionId}
            onAccountAssociationBtnClick={onAccountAssociationBtnClick}
            onToTheClientBalanceBtnClick={onToTheClientBalanceBtnClick}
            onConversionBtnClick={onConversionBtnClick}
            onCheckTransactionsBtnClick={onCheckTransactionsBtnClick}
            onConductBtnClick={onConductBtnClick}
            onCreateDebtBtnClick={onCreateDebtBtnClick}
            hasCurrentTranscationAssociation={hasCurrentTranscationAssociation}
          />
        </div>
        <BankClientContextMenu
          onAccountAssociationBtnClick={onAccountAssociationBtnClick}
          onToTheClientBalanceBtnClick={onToTheClientBalanceBtnClick}
          onConversionBtnClick={onConversionBtnClick}
          onCheckTransactionsBtnClick={onCheckTransactionsBtnClick}
          onConductBtnClick={onConductBtnClick}
          onCreateDebtBtnClick={onCreateDebtBtnClick}
          menuVisible={menuVisible}
          menuPosition={menuPosition}
          hasCurrentTranscationAssociation={hasCurrentTranscationAssociation}
          currentBankTransactionId={currentBankTransactionId}
        />
      </SessionFiltersContainer>
    </div>
  );
};

export default withTabs({isClosableTabs: true, label: "Bank operation register"})(BankClient);
