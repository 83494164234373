import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";

const useSwal = () => {
  const {t} = useTranslation();

  return {
    confirm: ({confirmFunc = () => {}}) => {
      Swal.fire({
        title: "",
        text: `${t("Are you sure?")}`,
        icon: "question",
        confirmButtonText: [t("Yes")],
        showCancelButton: true,
        cancelButtonText: [t("No")],
      }).then(answ => {
        if (answ.isConfirmed) {
          confirmFunc();
        }
      });
    },
    info: ({confirmFunc = () => {}, text = "", title = "", noTitle, showCancelButton = true}) => {
      Swal.fire({
        title: !noTitle ? t(title) || t("Information") : "",
        text: t(text),
        icon: "info",
        confirmButtonText: [t("Yes")],
        showCancelButton,
        cancelButtonText: [t("No")],
      }).then(answ => {
        if (answ.isConfirmed) {
          confirmFunc();
        }
      });
    },
  };
};

export default useSwal;
